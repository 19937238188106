import { auth, db, saveTokenToFirestore } from "../firebase";
import { getToken } from "firebase/messaging";
import {
  collection,
  doc,
  getDoc,
  setDoc,
  updateDoc,
  deleteField,
  arrayUnion,
  arrayRemove,
  increment,
  query,
  orderBy,
  onSnapshot,
  getDocs,
  addDoc,
  where,
  serverTimestamp,
} from "firebase/firestore";

export const requestNotificationPermission = async (messaging) => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      const token = await getToken(messaging, {
        vapidKey:
          "BN-njmyixSEuzLm64hdgPik9iz4vDxG7XxtsRJD6WHNply94JZpXtmkaHOeGFwUMqDZMOPEnrt-eJTBBZZkIbbQ",
      });
      console.log("FCM token:", token);

      if (token) {
        await saveTokenToFirestore(token);
      } else {
        console.error("No se pudo obtener el token FCM");
      }
    } else {
      console.error("Permiso de notificaciones denegado");
    }
  } catch (error) {
    console.error("Error al obtener el token FCM:", error);
  }
};

export const getCountryFromCoordinates = async (latitude, longitude) => {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBG6H69JDv-9Y0O2XJXhvHgdOIJiuGzRt0`
    );
    const data = await response.json();
    const country = data.results[0].address_components.find((component) =>
      component.types.includes("country")
    ).long_name;
    console.log(country);
    return country;
  } catch (error) {
    console.error("Error al obtener el país:", error);
    return null;
  }
};

export const formatDate = (date) => {
  if (date instanceof Date) {
    return date.toLocaleDateString();
  } else if (date && date.toDate instanceof Function) {
    return date.toDate().toLocaleDateString();
  }
  return "";
};

export const getDomainFromUrl = (url) => {
  if (!url) {
    return url;
  }

  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    url = "http://" + url;
  }
  const domain = new URL(url).hostname;
  const dotIndex = domain.lastIndexOf(".");

  if (dotIndex === -1) {
    return domain;
  }

  const shortenedDomain = domain.substring(0, dotIndex);

  if (shortenedDomain.length > 15) {
    return shortenedDomain.slice(0, 15) + "...";
  }

  return shortenedDomain;
};

export const handleMeSirve = async (
  offerId,
  userAuthenticated,
  userId,
  setOffers,
  setUserLikes
) => {
  if (!userAuthenticated) {
    console.log("Debes iniciar sesión para usar esta función");
    return;
  }

  const offerRef = doc(db, "offers", offerId);
  const userLikesRef = doc(db, "userLikes", userId);

  try {
    const offerDoc = await getDoc(offerRef);
    const offerData = offerDoc.data();
    const currentLikes = offerData.likes || [];

    if (currentLikes.includes(userId)) {
      await updateDoc(offerRef, {
        likes: arrayRemove(userId),
        helpedCount: increment(-1),
      });
      await updateDoc(userLikesRef, {
        [offerId]: deleteField(),
      });
      console.log("'Me sirve' removido");
    } else {
      await updateDoc(offerRef, {
        likes: arrayUnion(userId),
        helpedCount: increment(1),
      });
      await setDoc(userLikesRef, { [offerId]: true }, { merge: true });
      console.log("'Me sirve' agregado");
    }

    setOffers((prevOffers) =>
      prevOffers.map((offer) =>
        offer.id === offerId
          ? {
              ...offer,
              helpedCount:
                offer.helpedCount + (currentLikes.includes(userId) ? -1 : 1),
            }
          : offer
      )
    );
    setUserLikes((prevLikes) => ({
      ...prevLikes,
      [offerId]: !prevLikes[offerId],
    }));
  } catch (error) {
    console.error("Error al actualizar 'Me sirve':", error);
  }
};

export const handleFollow = async (
  companyId,
  uid,
  following,
  setFollowing,
  setLoading
) => {
  try {
    setLoading(true);
    const userFollowingRef = doc(db, "userFollowing", uid);
    const companyFollowersRef = doc(db, "companyFollowers", companyId);

    const isFollowing = following.includes(companyId);

    if (isFollowing) {
      await updateDoc(userFollowingRef, {
        [companyId]: deleteField(),
      });
      await updateDoc(companyFollowersRef, {
        [uid]: deleteField(),
      });
      setFollowing((prev) => prev.filter((id) => id !== companyId));
    } else {
      await setDoc(userFollowingRef, { [companyId]: true }, { merge: true });
      await setDoc(companyFollowersRef, { [uid]: true }, { merge: true });
      setFollowing((prev) => [...prev, companyId]);
    }
    setLoading(false);
  } catch (error) {
    console.error("Error al manejar el seguimiento de la empresa:", error);
    setLoading(false);
  }
};

export const filterOffersByCountry = async (
  coordinates,
  offers,
  setFilteredOffers
) => {
  if (coordinates) {
    const country = await getCountryFromCoordinates(
      coordinates.latitude,
      coordinates.longitude
    );

    if (country) {
      const filtered = offers.filter((offer) => offer.country === country);
      setFilteredOffers(filtered);
    } else {
      setFilteredOffers(offers);
    }
  }
};

export const fetchUserLikes = async (userAuthenticated, setUserLikes) => {
  if (userAuthenticated && auth.currentUser) {
    const userLikesRef = doc(db, "userLikes", auth.currentUser.uid);
    const userLikesDoc = await getDoc(userLikesRef);
    if (userLikesDoc.exists()) {
      setUserLikes(userLikesDoc.data());
    }
  }
};

export const fetchFollowing = async (setFollowing, setLoading) => {
  try {
    const { uid } = auth.currentUser || {};
    if (uid) {
      const userFollowingRef = doc(db, "userFollowing", uid);
      const userFollowingDoc = await getDoc(userFollowingRef);

      if (userFollowingDoc.exists()) {
        const followingData = userFollowingDoc.data();
        const followingIds = Object.keys(followingData);
        setFollowing(followingIds);
      } else {
        setFollowing([]);
      }
    }
    setLoading(false);
  } catch (error) {
    console.error("Error al obtener las empresas seguidas:", error);
    setLoading(false);
  }
};

export const fetchOffersAndStats = (setLoading, setOffers) => {
  try {
    setLoading(true);
    const offersCollectionRef = collection(db, "offers");

    const unsubscribeOffers = onSnapshot(
      query(offersCollectionRef, orderBy("createdAt", "desc")),
      (snapshot) => {
        const offersData = snapshot.docs.map((doc) => {
          const data = doc.data();
          let imageUrls = [];
          if (data.images && Array.isArray(data.images)) {
            imageUrls = data.images;
          } else if (data.images && typeof data.images === "string") {
            imageUrls = [data.images];
          }

          return {
            id: doc.id,
            ...data,
            uid: doc.data().uid,
            uide: doc.data().uide,
            imageUrls,
            helpedCount: data.helpedCount || 0,
          };
        });
        setLoading(false);
        setOffers(offersData);
        console.log("Ofertas obtenidas:", offersData);
      },
      (error) => {
        console.error("Error fetching offers:", error);
        setLoading(false);
      }
    );

    return unsubscribeOffers;
  } catch (error) {
    console.error("Error al obtener ofertas o estadísticas:", error);
    setLoading(false);
    return () => {}; // Return an empty function if there's an error
  }
};

export const fetchQuestionsAndAnswers = (offerId, setQuestions) => {
  const q = query(
    collection(db, "questions"),
    where("offerId", "==", offerId),
    orderBy("createdAt", "asc")
  );

  const unsubscribe = onSnapshot(q, (snapshot) => {
    const fetchedQuestions = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setQuestions(fetchedQuestions);
  });

  return unsubscribe;
};

export const postQuestion = async (offerId, userId, questionText) => {
  try {
    await addDoc(collection(db, "questions"), {
      offerId,
      userId,
      question: questionText,
      createdAt: serverTimestamp(),
    });
  } catch (error) {
    console.error("Error al publicar la pregunta: ", error);
  }
};

export const postAnswer = async (questionId, userId, answerText, offerId) => {
  try {
    // Asumiendo que tienes una colección 'answers' para almacenar respuestas
    await addDoc(collection(db, "answers"), {
      questionId,
      userId,
      offerId,
      answer: answerText,
      createdAt: serverTimestamp(),
    });
  } catch (error) {
    console.error("Error al publicar la respuesta: ", error);
  }
};

export const fetchAnswers = (offerId, setAnswers) => {
  const q = query(
    collection(db, "answers"),
    where("offerId", "==", offerId),
    orderBy("createdAt", "asc")
  );

  const unsubscribe = onSnapshot(q, (snapshot) => {
    const fetchedAnswers = snapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    console.log("Respuestas obtenidas:", fetchedAnswers);
    setAnswers(fetchedAnswers);
  });

  return unsubscribe;
};

export const fetchQuestionsAndAnswersWithUserData = (
  offerId,
  setQuestionsAndAnswers
) => {
  const q = query(
    collection(db, "questions"),
    where("offerId", "==", offerId),
    orderBy("createdAt", "asc")
  );

  const unsubscribe = onSnapshot(q, async (snapshot) => {
    const questionsAndAnswers = [];
    for (const questionDoc of snapshot.docs) {
      const questionData = questionDoc.data();

      // Fetch user data for the question
      const userDocRef = doc(db, "users", questionData.userId);
      const userDocSnap = await getDoc(userDocRef);
      const userData = userDocSnap.data();

      // Fetch answers for the question
      const answersQuery = query(
        collection(db, "answers"),
        where("questionId", "==", questionDoc.id)
      );
      const answersSnapshot = await getDocs(answersQuery);
      const answers = await Promise.all(
        answersSnapshot.docs.map(async (answerDoc) => {
          const answerData = answerDoc.data();

          // Fetch user data for the answer
          const answerUserDocRef = doc(db, "users", answerData.userId);
          const answerUserDocSnap = await getDoc(answerUserDocRef);
          const answerUserData = answerUserDocSnap.data();

          return {
            id: answerDoc.id,
            ...answerData,
            displayName: answerUserData.displayName,
            profileImageUrl: answerUserData.profileImageUrl,
          };
        })
      );

      questionsAndAnswers.push({
        id: questionDoc.id,
        ...questionData,
        displayName: userData.displayName,
        profileImageUrl: userData.profileImageUrl,
        answers: answers,
      });
    }

    setQuestionsAndAnswers(questionsAndAnswers);
  });

  return unsubscribe;
};

export const isCouponAvailable = async (cupon, userId) => {
  try {
    const usedCouponsRef = collection(db, "usedCoupons");
    const q = query(
      usedCouponsRef,
      where("cupon", "==", cupon),
      where("userId", "==", userId)
    );
    const querySnapshot = await getDocs(q);
    return querySnapshot.empty;
  } catch (error) {
    console.error("Error al verificar cupón:", error);
    return false;
  }
};

// Procesa el QR escaneado
export const handleQRScan = async (qrData) => {
  try {
    // Si el QR es un string JSON, intentar parsearlo
    let processedData = qrData;
    if (typeof qrData === 'string') {
      try {
        processedData = JSON.parse(qrData);
      } catch (e) {
        console.log('QR data is not JSON');
      }
    }

    // Si es un QR de pago
    if (processedData.type === 'payment') {
      return {
        isValid: true,
        data: {
          type: 'payment',
          merchantId: processedData.merchantId,
          amount: processedData.amount,
          storeName: processedData.storeName,
          timestamp: processedData.timestamp
        }
      };
    }

    // Si es un QR de cupón
    const offersRef = collection(db, "offers");
    const q = query(offersRef, where("cupon", "==", processedData));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const offerDoc = querySnapshot.docs[0];
      const offerData = offerDoc.data();
      
      return {
        isValid: true,
        data: {
          offerId: offerDoc.id,
          cupon: offerData.cupon,
          discount: offerData.offer,
          storeName: offerData.name,
          product: offerData.product,
          description: offerData.description,
          originalPrice: offerData.originalPrice || 0,
          image: offerData.image || null
        }
      };
    }
    
    return {
      isValid: false,
      error: "Código QR no válido"
    };
  } catch (error) {
    console.error('Error procesando QR:', error);
    return {
      isValid: false,
      error: "Error al procesar el código QR"
    };
  }
};

// Procesa el pago con el cupón
export const processPaymentWithCoupon = async (offerData, cardData, userId) => {
  try {
    // Verificar si el cupón ya fue usado
    const isAvailable = await isCouponAvailable(offerData.cupon, userId);
    if (!isAvailable) {
      return {
        success: false,
        error: "Este cupón ya ha sido utilizado"
      };
    }

    // Calcular el descuento
    const discountAmount = (offerData.originalPrice * offerData.discount) / 100;
    const finalPrice = offerData.originalPrice - discountAmount;

    // Registrar uso del cupón
    await addDoc(collection(db, "usedCoupons"), {
      userId,
      cupon: offerData.cupon,
      offerId: offerData.offerId,
      discount: offerData.discount,
      originalPrice: offerData.originalPrice,
      finalPrice,
      usedAt: serverTimestamp()
    });

    // Registrar el pago
    await addDoc(collection(db, "payments"), {
      userId,
      offerId: offerData.offerId,
      cupon: offerData.cupon,
      cardId: cardData.id,
      discount: offerData.discount,
      originalPrice: offerData.originalPrice,
      finalPrice,
      storeName: offerData.storeName,
      timestamp: serverTimestamp(),
      status: "completed"
    });

    // Actualizar estadísticas
    const offerRef = doc(db, "offers", offerData.offerId);
    await updateDoc(offerRef, {
      usedCount: increment(1)
    });

    return {
      success: true,
      message: "Pago procesado exitosamente"
    };
  } catch (error) {
    console.error("Error en el pago:", error);
    return {
      success: false,
      error: "Error al procesar el pago"
    };
  }
};