/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from "react";
import {
  Container,
  AppBar,
  Toolbar,
  CardMedia,
  IconButton,
  Box,
  Typography,
  Button,
  ListItem,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Avatar,
  TextField,
  Zoom,
  Paper,
  Fab,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import "react-calendar/dist/Calendar.css";
import { useNavigate } from "react-router-dom";
import { isLoggedIn } from "../session";
import { auth, db } from "../firebase";
import logoSvg from "../imgs/logo.svg";
import NotificationIcon, { subscribeToNotifications } from "./NotificationIcon";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getFirestore, doc, getDoc } from "firebase/firestore";
import {
  formatDate,
  useListenForNewPostsAndEvents,
  fetchFollowing,
  fetchEvents,
  fetchAttendees,
  handleAttend,
  handleFollow,
} from "./eventsUtils";
import CustomToolbar from "./CustomToolbar";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import CloseIcon from "@mui/icons-material/Close";
import AttendeesDialog from "./AttendeesDialog";

const Events = () => {
  const [loading, setLoading] = useState(false);
  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  const [events, setEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [following, setFollowing] = useState([]);
  const [attendees, setAttendees] = useState({});
  const [attendeeProfiles, setAttendeeProfiles] = useState({});
  const [openImageModal, setOpenImageModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [modalImages, setModalImages] = useState([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [hasSearched, setHasSearched] = useState(false);
  const searchTimeout = useRef(null);
  const navigate = useNavigate();
  const [aspectRatio, setAspectRatio] = useState("9:16"); // Valor predeterminado
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [userProfiles, setUserProfiles] = useState({});
  const [isLoadingAttendees, setIsLoadingAttendees] = useState(false);
  const [openAttendeesDialog, setOpenAttendeesDialog] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const db = getFirestore();
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnapshot = await getDoc(userDocRef);

          if (userDocSnapshot.exists()) {
            const userData = userDocSnapshot.data();
            if (userData.profileImageUrl) {
              setProfileImageUrl(userData.profileImageUrl);
            }
          }
        } catch (error) {
          console.error("Error fetching profile image:", error);
        }
      } else {
        setProfileImageUrl(null);
      }
    });

    return unsubscribe;
  }, []);

  const handleOpenAttendeesDialog = async (eventId) => {
    setSelectedEventId(eventId);
    setOpenAttendeesDialog(true);
    setIsLoadingAttendees(true);

    if (attendees[eventId]?.length > 0) {
      await fetchUserProfiles(attendees[eventId]);
    }

    setIsLoadingAttendees(false);
  };

  const fetchUserProfiles = async (attendeeIds) => {
    try {
      const profiles = { ...userProfiles };
      const newAttendeeIds = attendeeIds.filter((id) => !profiles[id]);

      await Promise.all(
        newAttendeeIds.map(async (attendeeId) => {
          const userDocRef = doc(db, "users", attendeeId);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            profiles[attendeeId] = {
              id: attendeeId,
              name: userData.name || userData.displayName || "Usuario",
              profileImageUrl: userData.profileImageUrl || "",
              email: userData.email || "",
              // Add any other user fields you want to display
            };
          }
        })
      );

      setUserProfiles(profiles);
      return profiles;
    } catch (error) {
      console.error("Error fetching user profiles:", error);
      return {};
    }
  };

  const handleLoadedMetadata = (e) => {
    const video = e.target;
    const ratio = video.videoWidth / video.videoHeight;
    setAspectRatio(ratio > 1 ? "16:9" : "9:16"); // Detecta si es 16:9 o 9:16
  };

  useListenForNewPostsAndEvents();

  useEffect(() => {
    if (!isLoggedIn()) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    const unsubscribe = subscribeToNotifications(setHasNewNotifications);
    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    const unsubscribe = fetchEvents(setLoading, setEvents);
    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    fetchFollowing(setFollowing, setLoading);
  }, []);

  useEffect(() => {
    fetchAttendees(events, setAttendees, setAttendeeProfiles);
  }, [events]);

  const handleCloseAttendeesDialog = useCallback(() => {
    setOpenAttendeesDialog(false);
    setSelectedEventId(null);
  }, []);

  const handleAttendeeClick = useCallback(
    (attendeeId) => {
      navigate(`/perfil/${attendeeId}`);
      handleCloseAttendeesDialog();
    },
    [navigate]
  );

  const handleAttendEvent = useCallback(
    async (eventId) => {
      await handleAttend(eventId);
      fetchAttendees(events, setAttendees, setAttendeeProfiles);
    },
    [events]
  );

  const handleFollowCompany = useCallback(
    async (companyId) => {
      await handleFollow(companyId, following, setFollowing, setLoading);
    },
    [following]
  );

  const handleOpenImageModal = useCallback((images) => {
    setModalImages(images);
    setOpenImageModal(true);
  }, []);

  const handleCloseImageModal = useCallback(() => {
    setOpenImageModal(false);
  }, []);

  const handleNextImage = useCallback(() => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === modalImages.length - 1 ? 0 : prevIndex + 1
    );
  }, [modalImages.length]);

  const handlePreviousImage = useCallback(() => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? modalImages.length - 1 : prevIndex - 1
    );
  }, [modalImages.length]);

  const handleClickPerfil = useCallback(
    (event) => {
      if (event) {
        navigate(`/perfil/${event.uid}`);
      }
    },
    [navigate]
  );

  const isCompanyFollowed = useCallback(
    (companyId) => following.includes(companyId),
    [following]
  );

  const handleSearch = useCallback(
    (term) => {
      if (!Array.isArray(events)) {
        console.error("events is not an array:", events);
        return;
      }
      const searchValue = term.toLowerCase().trim();
      const filteredResults = events.filter(
        (event) =>
          event.name.toLowerCase().includes(searchValue) ||
          (event.title && event.title.toLowerCase().includes(searchValue))
      );
      setFilteredEvents(filteredResults);
      setHasSearched(searchValue.length > 0);
    },
    [events]
  );

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    searchTimeout.current = setTimeout(() => {
      handleSearch(value);
    }, 300);
  };

  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    if (!isSearchOpen) {
      setTimeout(() => {
        document.getElementById("search-input")?.focus();
      }, 100);
    } else {
      setSearchTerm("");
      handleSearch("");
      setHasSearched(false);
    }
  };

  const styles = {
    // Estilos del contenedor principal
    root: {
      backgroundColor: "#f5f7fa",
      minHeight: "100vh",
      paddingBottom: "80px",
    },

    // Estilos de la barra superior y logo
    topAppBar: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      backgroundColor: "#fff",
      boxShadow: "0 2px 8px rgba(0,0,0,0.08)",
    },
    logoToolbar: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "12px 0",
    },
    media: {
      width: "160px",
      marginTop: "15px",
      marginBottom: "15px",
      transition: "transform 0.2s ease",
      "&:hover": {
        transform: "scale(1.02)",
      },
    },

    // Estilos de las tarjetas de eventos
    listItem: {
      display: "block",
      width: "100%",
      border: "none",
      margin: "16px auto",
      padding: "20px",
      backgroundColor: "#fff",
      borderRadius: "12px",
      boxShadow: "0 2px 12px rgba(0,0,0,0.08)",
      transition: "transform 0.2s ease, box-shadow 0.2s ease",
      "&:hover": {
        transform: "translateY(-2px)",
        boxShadow: "0 4px 15px rgba(0,0,0,0.12)",
      },
    },

    // Estilos del perfil y encabezado
    contUser: {
      display: "flex",
      alignItems: "center",
      gap: "12px",
      padding: "12px 0",
      borderBottom: "1px solid #f0f0f0",
      marginBottom: "16px",
    },
    name: {
      color: "#312783",
      fontSize: "22px",
      fontWeight: "600",
      textDecoration: "none",
      cursor: "pointer",
      transition: "color 0.2s ease",
      "&:hover": {
        color: "#251e62",
      },
    },

    // Estilos del contenido del evento
    eventContent: {
      padding: "0 16px",
    },
    title: {
      color: "#00897b",
      fontSize: "20px",
      fontWeight: "500",
      marginBottom: "12px",
      lineHeight: 1.4,
    },
    description: {
      color: "#4a4a4a",
      fontSize: "15px",
      lineHeight: 1.6,
      marginBottom: "16px",
    },
    event: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      fontSize: "16px",
      fontWeight: "500",
      color: "#2c2c2c",
      marginBottom: "12px",
      padding: "8px 12px",
      backgroundColor: "#f8f9fa",
      borderRadius: "8px",
    },
    date: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      color: "#1976d2",
      fontSize: "16px",
      fontWeight: "500",
      marginBottom: "16px",
      padding: "8px 12px",
      backgroundColor: "#e3f2fd",
      borderRadius: "8px",
    },
    created: {
      color: "#757575",
      fontSize: "12px",
      marginTop: "12px",
      marginBottom: "16px",
    },

    // Estilos de los medios
    mediaContainer: {
      position: "relative",
      width: "100%",
      borderRadius: "12px",
      overflow: "hidden",
      backgroundColor: "#f8f9fa",
      marginBottom: "16px",
    },
    mainImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      transition: "transform 0.3s ease",
      "&:hover": {
        transform: "scale(1.02)",
      },
    },
    additionalImagesOverlay: {
      position: "absolute",
      bottom: "12px",
      right: "12px",
      backgroundColor: "rgba(0,0,0,0.75)",
      color: "white",
      padding: "8px 12px",
      borderRadius: "8px",
      display: "flex",
      alignItems: "center",
      gap: "8px",
      cursor: "pointer",
      transition: "background-color 0.2s ease",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,0.9)",
      },
    },

    // Estilos de los botones y acciones
    actionButton: {
      textTransform: "none",
      borderRadius: "8px",
      padding: "10px 16px",
      fontWeight: "500",
      border: "2px solid #312783",
      color: "#312783",
      transition: "all 0.2s ease",
      "&:hover": {
        backgroundColor: "#312783",
        color: "white",
      },
    },
    attendeesContainer: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      marginBottom: "16px",
      padding: "12px",
      backgroundColor: "#f8f9fa",
      borderRadius: "8px",
    },
    attendeeAvatar: {
      width: 32,
      height: 32,
      border: "2px solid #312783",
      transition: "transform 0.2s ease",
      "&:hover": {
        transform: "scale(1.1)",
      },
    },
    attendeeCount: {
      color: "#666",
      fontSize: "14px",
      fontWeight: "500",
    },

    // Estilos del modal de imágenes
    imageModal: {
      backgroundColor: "rgba(0,0,0,0.9)",
    },
    imageModalContent: {
      maxWidth: "95%",
      maxHeight: "90vh",
      position: "relative",
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    modalImage: {
      maxWidth: "100%",
      maxHeight: "80vh",
      borderRadius: "8px",
      objectFit: "contain",
    },
    navigationButton: {
      position: "absolute",
      backgroundColor: "rgba(255,255,255,0.1)",
      color: "white",
      borderRadius: "50%",
      padding: "12px",
      backdropFilter: "blur(8px)",
      "&:hover": {
        backgroundColor: "rgba(255,255,255,0.2)",
      },
    },

    eventTypeIcon: {
      padding: "4px",
      borderRadius: "50%",
      backgroundColor: "#e8f5e9",
      color: "#2e7d32",
      marginRight: "8px",
    },
    dateIcon: {
      padding: "4px",
      borderRadius: "50%",
      backgroundColor: "#e3f2fd",
      color: "#1976d2",
      marginRight: "8px",
    },
    attendButton: {
      marginTop: "16px",
      backgroundColor: "#312783",
      color: "white",
      "&:hover": {
        backgroundColor: "#251e62",
      },
    },
    followButton: {
      marginTop: "8px",
      borderColor: "#312783",
      color: "#312783",
      "&:hover": {
        borderColor: "#251e62",
        backgroundColor: "rgba(49,39,131,0.04)",
      },
    },
  };

  const classes = {
    appBar: {
      top: "auto",
      bottom: 0,
      backgroundColor: "white",
      color: "grey",
    },
    toolbar: {
      justifyContent: "space-around",
    },
    iconButton: {
      display: "flex",
      justifyContent: "center",
      color: "grey",
    },
    icon: {
      color: "grey",
    },
    avatar: {
      width: 24,
      height: 24,
      border: "2.3px solid grey",
    },
  };

  // Estilos adicionales para elementos específicos
  const additionalStyles = {
    searchFab: {
      position: "fixed",
      top: 180,
      right: 16,
      zIndex: 1000,
      backgroundColor: "#312783",
      "&:hover": {
        backgroundColor: "#251e62",
      },
    },
    searchContainer: {
      position: "fixed",
      top: 180,
      right: 16,
      zIndex: 1000,
      display: "flex",
      alignItems: "center",
      backgroundColor: "white",
      borderRadius: "24px",
      boxShadow: "0 2px 12px rgba(0,0,0,0.15)",
      padding: "8px 16px",
      transition: "all 0.3s ease",
    },
    searchInput: {
      marginRight: "8px",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiInputBase-input": {
        padding: "8px 12px",
      },
    },
    noResults: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "32px",
      textAlign: "center",
      marginTop: "32px",
      backgroundColor: "#fff",
      borderRadius: "12px",
      boxShadow: "0 2px 12px rgba(0,0,0,0.08)",
      gap: "16px",
    },
    noResultsIcon: {
      fontSize: "48px",
      color: "#9e9e9e",
      marginBottom: "8px",
    },
  };

  const NoResults = () => (
    <Paper sx={additionalStyles.noResults}>
      <SentimentDissatisfiedIcon sx={additionalStyles.noResultsIcon} />
      <Typography variant="h6">No encontramos resultados</Typography>
      <Typography color="textSecondary">
        No hay eventos o tiendas que coincidan con "{searchTerm}"
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setSearchTerm("");
          handleSearch("");
          setHasSearched(false);
        }}
      >
        Limpiar búsqueda
      </Button>
    </Paper>
  );

  const renderFloatingSearch = () => (
    <Box>
      <Zoom in={!isSearchOpen}>
        <Fab
          color="primary"
          aria-label="search"
          onClick={toggleSearch}
          sx={additionalStyles.searchFab}
        >
          <SearchIcon />
        </Fab>
      </Zoom>

      <Zoom in={isSearchOpen}>
        <Paper sx={additionalStyles.searchContainer}>
          <TextField
            id="search-input"
            placeholder="Buscar evento o tienda"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={additionalStyles.searchInput}
          />
          <IconButton onClick={toggleSearch} size="small">
            <CloseIcon />
          </IconButton>
        </Paper>
      </Zoom>
    </Box>
  );

  const renderEventList = useMemo(() => {
    const eventsToRender = searchTerm.trim() !== "" ? filteredEvents : events;
    return eventsToRender.map((event) => (
      <ListItem key={event.id} style={styles.listItem}>
        <Box style={styles.contUser}>
          <CardMedia
            component="img"
            image={event.image}
            title="Perfil"
            style={{
              width: 40,
              height: 40,
              borderRadius: "50%",
              marginRight: 16,
              border: "2.5px solid #312783",
            }}
          />
          <Typography
            onClick={() => handleClickPerfil(event)}
            style={styles.name}
          >
            {event.name}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "flex-start",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
              width: { xs: "100%", md: "20%" },
              alignItems: { xs: "center", md: "flex-start" },
              mb: { xs: 2, md: 0 },
            }}
          >
            {event.videos && event.videos.length > 0 ? (
              <div
                style={{
                  position: "relative",
                  width: "100%",
                  paddingBottom: aspectRatio === "16:9" ? "56.25%" : "177.78%", // Aspecto dinámico para 16:9 y 9:16
                  backgroundColor: "#000", // Fondo para cuando el video no se carga
                  overflow: "hidden",
                  borderRadius: "5px",
                }}
              >
                <video
                  src={event.videos[0]}
                  controls
                  muted
                  playsInline
                  autoPlay
                  onLoadedMetadata={handleLoadedMetadata}
                  style={{
                    position: "absolute",
                    top: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    objectFit: "cover", // Ajusta el video sin distorsión
                  }}
                />
              </div>
            ) : event.images && event.images.length > 0 ? (
              <Box style={styles.imageContainer}>
                <CardMedia
                  component="img"
                  image={event.images[0]}
                  alt={`Imagen principal de ${event.name}`}
                  style={styles.mainImage}
                />
                {event.images.length > 1 && (
                  <Box
                    style={styles.additionalImagesOverlay}
                    onClick={() => handleOpenImageModal(event.images)}
                  >
                    <Typography style={styles.additionalImagesCount}>
                      +{event.images.length - 1}
                    </Typography>
                    <Typography>Ver más</Typography>
                  </Box>
                )}
              </Box>
            ) : (
              <Box style={styles.noImage}>
                <Typography>No hay medios disponibles</Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
              alignItems: "flex-start",
              marginLeft: "30px",
              width: { xs: "90%", md: "70%" },
            }}
          >
            <Typography style={styles.title}>{event.title}</Typography>
            <Typography style={styles.description}>
              {event.description}
            </Typography>
            <Typography style={styles.event}>
              Tipo de evento: {event.eventType}
            </Typography>
            <Typography style={styles.date}>
              Fecha: {formatDate(event.date)}
            </Typography>
            <Typography style={styles.created}>
              Fecha de publicación: {formatDate(event.createdAt)}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography style={styles.description}>Asistirán:</Typography>
              {attendees[event.id]?.slice(0, 3).map((attendeeId) => (
                <Avatar
                  key={attendeeId}
                  src={attendeeProfiles[attendeeId]?.profileImageUrl || ""}
                  alt="Avatar"
                  sx={{
                    width: 24,
                    height: 24,
                    mr: "1px",
                    border: "1px solid #312783",
                    borderRadius: "50%",
                    mb: 2,
                    ml: 2,
                  }}
                />
              ))}
              {attendees[event.id]?.length > 3 && (
                <Typography
                  variant="caption"
                  onClick={() => handleOpenAttendeesDialog(event.id)}
                  sx={{
                    ml: 1,
                    color: "#312783",
                    cursor: "pointer",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                    transition: "color 0.2s ease",
                    fontWeight: 500,
                  }}
                >
                  y {attendees[event.id].length - 3} más
                </Typography>
              )}
            </Box>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={() => handleAttendEvent(event.id)}
              disabled={loading}
            >
              {attendees[event.id]?.includes(auth.currentUser?.uid)
                ? "No asistiré"
                : "Asistiré"}
            </Button>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={() => handleFollowCompany(event.uid)}
              disabled={loading}
              sx={{ mt: 2 }}
            >
              {isCompanyFollowed(event.uid) ? "Dejar de seguir" : "Seguir"}
            </Button>
          </Box>
        </Box>
      </ListItem>
    ));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    aspectRatio,
    filteredEvents,
    events,
    searchTerm,
    attendees,
    attendeeProfiles,
    handleAttendEvent,
    handleFollowCompany,
    isCompanyFollowed,
    handleClickPerfil,
    handleOpenImageModal,
  ]);

  const renderContent = () => {
    if (loading) {
      return (
        <div style={{ textAlign: "center", color: "#312783", marginTop: 20 }}>
          <CircularProgress />
        </div>
      );
    }

    if (hasSearched && filteredEvents.length === 0) {
      return <NoResults />;
    }

    return renderEventList;
  };

  return (
    <Container>
      <AppBar position="fixed" sx={classes.appBar}>
        <Toolbar sx={classes.toolbar}>
          <IconButton href="/home" sx={classes.iconButton}>
            <HomeIcon sx={classes.icon} />
          </IconButton>

          <IconButton href="/members" sx={classes.iconButton}>
            <SearchIcon sx={classes.icon} />
          </IconButton>

          <IconButton href="/notifications" sx={classes.iconButton}>
            <NotificationIcon
              hasNewNotifications={hasNewNotifications}
              sx={classes.icon}
            />
          </IconButton>

          <IconButton href="/profile" sx={classes.iconButton}>
            {profileImageUrl ? (
              <Avatar src={profileImageUrl} sx={classes.avatar} />
            ) : (
              <Avatar sx={classes.avatar} />
            )}
          </IconButton>
        </Toolbar>
      </AppBar>
      <AppBar
        position="fixed"
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          marginBottom: "10px",
          bgcolor: "#fff",
        }}
      >
        <Toolbar sx={{ flexDirection: "column", alignItems: "center" }}>
          <AppBar position="fixed" sx={styles.topAppBar}>
            <Toolbar sx={styles.logoToolbar}>
              <CardMedia
                component="img"
                image={logoSvg}
                title="Notiline"
                style={styles.media}
              />
            </Toolbar>
            <CustomToolbar />
          </AppBar>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 24, mb: 10 }}>
        {renderFloatingSearch()}
        {renderContent()}
        <Dialog
          open={openImageModal}
          onClose={handleCloseImageModal}
          aria-labelledby="image-modal-title"
          aria-describedby="image-modal-description"
          fullScreen
        >
          <DialogTitle id="image-modal-title">Imágenes del Evento</DialogTitle>
          <DialogContent>
            <DialogContentText id="image-modal-description">
              {openImageModal && (
                <Box style={styles.imageModalContent}>
                  {modalImages && modalImages.length > 0 ? (
                    <CardMedia
                      component="img"
                      image={modalImages[currentImageIndex]}
                      alt={`Imagen ${currentImageIndex + 1} del alojamiento`}
                      style={styles.imageModalImage}
                    />
                  ) : (
                    <Typography>No hay imágenes disponibles</Typography>
                  )}
                  <Box style={styles.imageModalNavigation}>
                    <IconButton onClick={handlePreviousImage}>
                      <ArrowBackIosIcon />
                    </IconButton>
                    <IconButton onClick={handleNextImage}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Box>
                </Box>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseImageModal}>Cerrar</Button>
          </DialogActions>
        </Dialog>
        <AttendeesDialog
          open={openAttendeesDialog}
          onClose={handleCloseAttendeesDialog}
          attendees={selectedEventId ? attendees[selectedEventId] : []}
          userProfiles={userProfiles}
          loading={isLoadingAttendees}
        />
      </Container>
    </Container>
  );
};

export default Events;
