/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useCallback, useRef } from "react";
import {
  Container,
  AppBar,
  Toolbar,
  CardMedia,
  IconButton,
  Box,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  CircularProgress,
  DialogContentText,
  TextField,
  Divider,
  Paper,
  Collapse,
  Avatar,
  Zoom,
  Fab,
} from "@mui/material";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { doc, onSnapshot, getFirestore } from "firebase/firestore";
import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import { Link } from "react-router-dom";
import WelcomeDialog from "./welcomeDialog";
import { useNavigate } from "react-router-dom";
import CopyToClipboardComponent from "./copy";
import { isLoggedIn } from "../session";
import { auth, messaging } from "../firebase";
import logoSvg from "../imgs/logo.svg";
import NotificationIcon, { subscribeToNotifications, useListenForNewMessages, useListenForNewPostsAndEvents } from "./NotificationIcon";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useWatchPosition } from "react-use-geolocation";
import {
  requestNotificationPermission,
  formatDate,
  getDomainFromUrl,
  handleMeSirve,
  handleFollow,
  fetchOffersAndStats,
  filterOffersByCountry,
  fetchUserLikes,
  fetchFollowing,
  fetchQuestionsAndAnswers,
  postQuestion,
  postAnswer,
  fetchAnswers,
  fetchQuestionsAndAnswersWithUserData,
} from "./homeUtils";
import CustomToolbar from "./CustomToolbar";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import CloseIcon from "@mui/icons-material/Close";
import QRScanner from "./QRScanner";

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [hasNewNotifications, setHasNewNotifications] = useState(false);
  const [userAuthenticated, setUserAuthenticated] = useState(false);
  const [userLikes, setUserLikes] = useState({});
  const [offers, setOffers] = useState([]);
  const [filteredOffers, setFilteredOffers] = useState([]);
  const [following, setFollowing] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [busqueda, setBusqueda] = useState("");
  const [openImageModal, setOpenImageModal] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [modalImages, setModalImages] = useState([]);
  const [questions, setQuestions] = useState({});
  const [newQuestion, setNewQuestion] = useState("");
  const [newAnswer, setNewAnswer] = useState({});
  const answerInputRef = useRef({});
  const [answers, setAnswers] = useState({});
  const [showMoreQuestions, setShowMoreQuestions] = useState({});
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState([]);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [hasSearched, setHasSearched] = useState(false);
  const searchTimeout = useRef(null);
  const [isQRScannerOpen, setIsQRScannerOpen] = useState(false);
  const [profileImageUrl, setProfileImageUrl] = useState(null);
  const [aspectRatio, setAspectRatio] = useState("9:16");

  useEffect(() => {
    let unsubscribeSnapshot = null;

    const setupProfileListener = (user) => {
      if (!user) {
        setProfileImageUrl(null);
        return;
      }

      unsubscribeSnapshot = onSnapshot(
        doc(getFirestore(), "users", user.uid),
        (doc) => setProfileImageUrl(doc.data()?.profileImageUrl ?? null),
        (error) => {
          console.error("Error listening to profile image:", error);
          setProfileImageUrl(null);
        }
      );
    };

    const unsubscribeAuth = auth.onAuthStateChanged(setupProfileListener);

    return () => {
      unsubscribeAuth();
      unsubscribeSnapshot?.();
    };
  }, []);

  // eslint-disable-next-line no-unused-vars
  const navigate = useNavigate();
  const { loaded, coordinates } = useWatchPosition();

  const handleLoadedMetadata = (e) => {
    const video = e.target;
    const ratio = video.videoWidth / video.videoHeight;
    setAspectRatio(ratio > 1 ? "16:9" : "9:16");
  };

  const handleOpenScanner = () => {
    if (!auth.currentUser) {
      alert("Debes iniciar sesión para usar esta función");
      return;
    }
    setIsQRScannerOpen(true);
  };

  useEffect(() => {
    const checkAuthAndRequestPermission = async () => {
      const loggedIn = await isLoggedIn();
      setUserAuthenticated(loggedIn);
      setLoading(false);

      if (loggedIn) {
        await requestNotificationPermission(messaging);
      }
    };

    checkAuthAndRequestPermission();
  }, []);

  useEffect(() => {
    const checkAuthAndRequestPermission = async () => {
      const loggedIn = await isLoggedIn();
      setUserAuthenticated(loggedIn);
      setLoading(false);

      if (loggedIn) {
        await requestNotificationPermission(messaging);
      }
    };

    checkAuthAndRequestPermission();
  }, []);

  useEffect(() => {
    const unsubscribeFunc = subscribeToNotifications(setHasNewNotifications);

    return () => {
      if (typeof unsubscribeFunc === "function") {
        unsubscribeFunc();
      }
    };
  }, []);

  useEffect(() => {
    if (userAuthenticated) {
      fetchUserLikes(userAuthenticated, setUserLikes);
    }
  }, [userAuthenticated]);

  useEffect(() => {
    const unsubscribe = fetchOffersAndStats(setLoading, setOffers);
    return () => {
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, []);

  useEffect(() => {
    if (loaded && coordinates) {
      filterOffersByCountry(coordinates, offers, setFilteredOffers);
    }
  }, [loaded, coordinates, offers]);

  useEffect(() => {
    fetchFollowing(setFollowing, setLoading);
  }, []);

  useListenForNewPostsAndEvents();

  useListenForNewMessages();

  const handleMeSirveClick = (offerId) => {
    handleMeSirve(
      offerId,
      userAuthenticated,
      auth.currentUser?.uid,
      setOffers,
      setUserLikes
    );
  };

  const handleFollowClick = (companyId) => {
    handleFollow(
      companyId,
      auth.currentUser?.uid,
      following,
      setFollowing,
      setLoading
    );
  };

  const buscar = useCallback(() => {
    if (!Array.isArray(offers)) {
      console.error("offers is not an array:", offers);
      return;
    }
    const valorBusqueda = busqueda.toLowerCase().trim();
    const resultadosFiltrados = offers.filter(
      (offer) =>
        offer.name.toLowerCase().includes(valorBusqueda) ||
        (offer.title && offer.title.toLowerCase().includes(valorBusqueda))
    );
    setFilteredOffers(resultadosFiltrados);
  }, [busqueda, offers]);

  useEffect(() => {
    buscar();
  }, [busqueda, offers, buscar]);

  const handleOpenImageModal = (images) => {
    setModalImages(images);
    setOpenImageModal(true);
  };

  const handleCloseImageModal = () => {
    setOpenImageModal(false);
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === modalImages.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePreviousImage = () => {
    setCurrentImageIndex((prevIndex) =>
      prevIndex === 0 ? modalImages.length - 1 : prevIndex - 1
    );
  };

  const isCompanyFollowed = useCallback(
    (companyId) => following.includes(companyId),
    [following]
  );

  useEffect(() => {
    if (offers.length > 0) {
      offers.forEach((offer) => {
        fetchQuestionsAndAnswers(offer.id, (fetchedQuestions) => {
          setQuestions((prevQuestions) => ({
            ...prevQuestions,
            [offer.id]: fetchedQuestions,
          }));
        });
        fetchAnswers(offer.id, (fetchedAnswers) => {
          setAnswers((prevAnswers) => ({
            ...prevAnswers,
            [offer.id]: fetchedAnswers,
          }));
        });
      });
    }
  }, [offers]);

  const handleQuestionSubmit = async (offerId) => {
    if (newQuestion.trim() !== "") {
      await postQuestion(offerId, auth.currentUser?.uid, newQuestion);
      setNewQuestion("");
    }
  };

  const handleAnswerSubmit = async (questionId, offerId) => {
    if (
      newAnswer[questionId]?.trim() !== "" &&
      !answers[offerId]?.some((answer) => answer.questionId === questionId)
    ) {
      await postAnswer(
        questionId,
        auth.currentUser?.uid,
        newAnswer[questionId],
        offerId
      );
      setNewAnswer((prevAnswers) => ({ ...prevAnswers, [questionId]: "" }));
    }
  };

  useEffect(() => {
    Object.entries(answers).forEach(([offerId, offerAnswers]) => {
      questions[offerId]?.forEach((question) => {
        const hasAnswer = offerAnswers.some(
          (answer) => answer.questionId === question.id
        );
        if (hasAnswer) {
          setNewAnswer((prevAnswers) => ({
            ...prevAnswers,
            [question.id]: "",
          }));
        }
      });
    });
  }, [answers, questions]);

  useEffect(() => {
    if (offers.length > 0) {
      offers.forEach((offer) => {
        fetchQuestionsAndAnswersWithUserData(offer.id, (fetchedData) => {
          setQuestionsAndAnswers((prevData) => ({
            ...prevData,
            [offer.id]: fetchedData,
          }));
        });
      });
    }
  }, [offers]);

  const handleSearch = useCallback(
    (term) => {
      if (!Array.isArray(offers)) {
        console.error("offers is not an array:", offers);
        return;
      }
      const searchValue = term.toLowerCase().trim();
      const filteredResults = offers.filter(
        (offer) =>
          offer.name.toLowerCase().includes(searchValue) ||
          (offer.title && offer.title.toLowerCase().includes(searchValue)) ||
          (offer.product && offer.product.toLowerCase().includes(searchValue))
      );
      setFilteredOffers(filteredResults);
      setHasSearched(searchValue.length > 0);
    },
    [offers]
  );

  // Add debounced search handler
  const handleSearchChange = (event) => {
    const value = event.target.value;
    setSearchTerm(value);

    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current);
    }

    searchTimeout.current = setTimeout(() => {
      handleSearch(value);
    }, 300);
  };

  // Add search toggle handlers
  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    if (!isSearchOpen) {
      setTimeout(() => {
        document.getElementById("search-input")?.focus();
      }, 100);
    } else {
      setSearchTerm("");
      handleSearch("");
      setHasSearched(false);
    }
  };

  // Add no results component
  const NoResults = () => (
    <Paper sx={additionalStyles.noResults}>
      <SentimentDissatisfiedIcon sx={additionalStyles.noResultsIcon} />
      <Typography variant="h6">No encontramos resultados</Typography>
      <Typography color="textSecondary">
        No hay productos o tiendas que coincidan con "{searchTerm}"
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setSearchTerm("");
          handleSearch("");
          setHasSearched(false);
        }}
      >
        Limpiar búsqueda
      </Button>
    </Paper>
  );

  // Add the floating search button and input field just before the return statement
  const renderFloatingSearch = () => (
    <Box>
      <Zoom in={!isSearchOpen}>
        <Fab
          color="primary"
          aria-label="search"
          onClick={toggleSearch}
          sx={additionalStyles.searchFab}
        >
          <SearchIcon />
        </Fab>
      </Zoom>

      <Zoom in={isSearchOpen}>
        <Paper sx={additionalStyles.searchContainer}>
          <TextField
            id="search-input"
            placeholder="Buscar producto o tienda"
            variant="outlined"
            size="small"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={additionalStyles.searchInput}
          />
          <IconButton onClick={toggleSearch} size="small">
            <CloseIcon />
          </IconButton>
        </Paper>
      </Zoom>
    </Box>
  );

  const styles = {
    // Estilos generales
    cont: {
      position: "absolute",
      left: "50%",
      transform: "translateX(-50%)",
      textAlign: "center",
      margin: 0,
      padding: 0,
      boxSizing: "border-box",
    },
    // Cabecera y logo
    topAppBar: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      backgroundColor: "#fff",
      boxShadow: "0 2px 8px rgba(0,0,0,0.1)",
      borderBottom: "1px solid rgba(0,0,0,0.08)",
    },
    logoToolbar: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: "10px 0",
    },
    media: {
      width: "160px",
      marginTop: "15px",
      marginBottom: "15px",
      transition: "transform 0.2s ease",
      "&:hover": {
        transform: "scale(1.02)",
      },
    },

    // Estilos de las publicaciones
    listItem: {
      display: "block",
      width: "100%",
      border: "none",
      margin: "16px auto",
      padding: "20px",
      backgroundColor: "#fff",
      borderRadius: "12px",
      boxShadow: "0 2px 12px rgba(0,0,0,0.08)",
      transition: "transform 0.2s ease, box-shadow 0.2s ease",
      "&:hover": {
        transform: "translateY(-2px)",
        boxShadow: "0 4px 15px rgba(0,0,0,0.1)",
      },
    },
    contUser: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "center",
      marginBottom: "16px",
      padding: "8px 0",
    },
    name: {
      color: "#312783",
      fontSize: "22px",
      fontWeight: "600",
      marginBottom: "4px",
      marginTop: "4px",
      textDecoration: "none",
      transition: "color 0.2s ease",
      "&:hover": {
        color: "#1a1464",
      },
    },
    title: {
      fontSize: "20px",
      marginBottom: "12px",
      color: "#2c2c2c",
      fontWeight: "500",
      lineHeight: "1.4",
    },
    description: {
      marginBottom: "16px",
      fontWeight: "400",
      color: "#4a4a4a",
      lineHeight: "1.6",
      fontSize: "15px",
    },
    offer: {
      fontSize: "17px",
      marginBottom: "16px",
      backgroundColor: "#e8f5e9",
      color: "#2e7d32",
      padding: "12px 16px",
      borderRadius: "8px",
      display: "flex",
      alignItems: "center",
      gap: "8px",
      fontWeight: "500",
    },
    cupon: {
      backgroundColor: "#f5f5f5",
      padding: "16px",
      borderRadius: "8px",
      fontSize: "18px",
      marginBottom: "16px",
      textAlign: "center",
      color: "#312783",
      fontWeight: "500",
      border: "1px dashed #dbdbdb",
    },
    created: {
      fontSize: "12px",
      marginBottom: "16px",
      color: "#757575",
    },

    // Estilos de medios
    imageContainer: {
      position: "relative",
      width: "100%",
      borderRadius: "8px",
      overflow: "hidden",
      backgroundColor: "#f8f9fa",
      marginBottom: "16px",
    },
    mainImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      transition: "transform 0.3s ease",
      "&:hover": {
        transform: "scale(1.02)",
      },
    },
    additionalImagesOverlay: {
      position: "absolute",
      bottom: "12px",
      right: "12px",
      backgroundColor: "rgba(0,0,0,0.75)",
      color: "white",
      padding: "8px 12px",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      cursor: "pointer",
      transition: "background-color 0.2s ease",
      "&:hover": {
        backgroundColor: "rgba(0,0,0,0.9)",
      },
    },

    // Estilos de los botones y acciones
    button: {
      textTransform: "none",
      borderRadius: "8px",
      padding: "10px 16px",
      fontWeight: "500",
      fontSize: "15px",
      boxShadow: "none",
      "&:hover": {
        boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
      },
    },
    actionButton: {
      marginTop: "12px",
      backgroundColor: "#312783",
      color: "white",
      "&:hover": {
        backgroundColor: "#251e62",
      },
    },
    followButton: {
      marginTop: "8px",
      borderColor: "#312783",
      color: "#312783",
      "&:hover": {
        borderColor: "#251e62",
        backgroundColor: "rgba(49,39,131,0.04)",
      },
    },

    // Estilos para el modal de imágenes
    imageModal: {
      backgroundColor: "rgba(0,0,0,0.9)",
    },
    imageModalContent: {
      maxWidth: "95%",
      maxHeight: "90vh",
      position: "relative",
      backgroundColor: "transparent",
      padding: "20px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    imageModalImage: {
      maxWidth: "100%",
      maxHeight: "80vh",
      objectFit: "contain",
      borderRadius: "8px",
    },
    imageModalNavigation: {
      position: "absolute",
      bottom: "20px",
      left: "50%",
      transform: "translateX(-50%)",
      display: "flex",
      gap: "16px",
      padding: "12px",
      backgroundColor: "rgba(255,255,255,0.1)",
      backdropFilter: "blur(8px)",
      borderRadius: "12px",
    },

    // Estilos responsivos
    mediaSection: {
      width: { xs: "100%", md: "40%" },
      marginBottom: { xs: "16px", md: 0 },
    },
    contentSection: {
      width: { xs: "100%", md: "60%" },
      paddingLeft: { xs: 0, md: "24px" },
    },
  };

  // Estilos para el bottom toolbar
  const classes = {
    appBar: {
      top: "auto",
      bottom: 0,
      backgroundColor: "white",
      color: "grey",
    },
    toolbar: {
      justifyContent: "space-around",
    },
    iconButton: {
      display: "flex",
      justifyContent: "center",
      color: "grey",
    },
    icon: {
      color: "grey",
    },
    avatar: {
      width: 24,
      height: 24,
      border: "2.3px solid grey",
    },
  };

  // Estilos adicionales para la búsqueda
  const additionalStyles = {
    searchFab: {
      position: "fixed",
      top: 180,
      right: 16,
      zIndex: 1000,
      backgroundColor: "#312783",
      "&:hover": {
        backgroundColor: "#251e62",
      },
    },
    searchContainer: {
      position: "fixed",
      top: 180,
      right: 16,
      zIndex: 1000,
      display: "flex",
      alignItems: "center",
      backgroundColor: "white",
      borderRadius: "24px",
      boxShadow: "0 2px 12px rgba(0,0,0,0.15)",
      padding: "8px 16px",
      transition: "all 0.3s ease",
    },
    searchInput: {
      marginRight: "8px",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiInputBase-input": {
        padding: "8px 12px",
      },
    },
    noResults: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      padding: "32px",
      textAlign: "center",
      marginTop: "32px",
      backgroundColor: "#fff",
      borderRadius: "12px",
      boxShadow: "0 2px 12px rgba(0,0,0,0.08)",
      gap: "16px",
    },
    noResultsIcon: {
      fontSize: "48px",
      color: "#9e9e9e",
      marginBottom: "8px",
    },
    qrFab: {
      position: "absolute",
      top: "-30px",
      left: "50%",
      transform: "translateX(-50%)",
      backgroundColor: "#312783",
      "&:hover": {
        backgroundColor: "#251e62",
      },
      width: "60px",
      height: "60px",
      boxShadow: "0 4px 10px rgba(0,0,0,0.3)",
      animation: "float 3s ease-in-out infinite",
    },
    scanArea: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "200px",
      height: "200px",
      border: "2px solid #312783",
      borderRadius: "12px",
      boxShadow: "0 0 0 100vmax rgba(0, 0, 0, 0.5)",
      animation: "scan 2s infinite",
    },
    "@keyframes float": {
      "0%": { transform: "translate(-50%, 0px)" },
      "50%": { transform: "translate(-50%, -10px)" },
      "100%": { transform: "translate(-50%, 0px)" },
    },
    "@keyframes scan": {
      "0%": { boxShadow: "0 0 0 100vmax rgba(0, 0, 0, 0.5)" },
      "50%": { boxShadow: "0 0 0 100vmax rgba(0, 0, 0, 0.3)" },
      "100%": { boxShadow: "0 0 0 100vmax rgba(0, 0, 0, 0.5)" },
    },
  };
  const QRButton = ({ onClick }) => {
    return (
      <Box
        sx={{
          position: "relative",
          width: "80px",
          height: "80px",
          perspective: "1000px",
          transform: "translateZ(0)",
          "& *": {
            boxSizing: "border-box",
          },
        }}
      >
        <Fab
          onClick={onClick}
          sx={{
            position: "absolute",
            top: "-30px",
            left: "50%",
            transform: "translateX(-50%)",
            width: "70px",
            height: "70px",
            backgroundColor: "white",
            border: "2px solid rgba(0,0,0,0.1)",
            boxShadow: (theme) => `
                0 5px 15px rgba(0,0,0,0.2),
                inset 0 -2px 10px rgba(0,0,0,0.1),
                inset 0 2px 10px rgba(255,255,255,0.8)
              `,
            overflow: "visible",
            transition: "all 0.3s ease-in-out",
            animation: "spherePulse 3s ease-in-out infinite",
            "&:hover": {
              transform: "translateX(-50%) scale(1.05)",
              backgroundColor: "white",
            },
            "&::before": {
              content: '""',
              position: "absolute",
              top: "0",
              left: "0",
              right: "0",
              bottom: "0",
              borderRadius: "50%",
              background:
                "radial-gradient(circle at 30% 30%, rgba(255,255,255,0.8) 0%, rgba(255,255,255,0.1) 50%, transparent 70%)",
              animation: "moveLight 5s ease-in-out infinite",
              pointerEvents: "none",
            },
            "@keyframes spherePulse": {
              "0%, 100%": {
                transform: "translateX(-50%) translateY(0px)",
                boxShadow: `
                    0 5px 15px rgba(0,0,0,0.2),
                    inset 0 -2px 10px rgba(0,0,0,0.1),
                    inset 0 2px 10px rgba(255,255,255,0.8)
                  `,
              },
              "50%": {
                transform: "translateX(-50%) translateY(-6px)",
                boxShadow: `
                    0 15px 15px rgba(0,0,0,0.1),
                    inset 0 -2px 10px rgba(0,0,0,0.1),
                    inset 0 2px 10px rgba(255,255,255,0.8)
                  `,
              },
            },
            "@keyframes moveLight": {
              "0%, 100%": {
                backgroundPosition: "30% 30%",
              },
              "50%": {
                backgroundPosition: "70% 70%",
              },
            },
            "@keyframes floatN": {
              "0%, 100%": {
                transform: "translateY(0px)",
              },
              "50%": {
                transform: "translateY(-2px)",
              },
            },
          }}
        >
          <Box
            sx={{
              position: "relative",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100%",
              animation: "floatN 3s ease-in-out infinite",
              zIndex: 2,
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                color: "#312783", // Azul francia
                textShadow: "0 1px 2px rgba(0,0,0,0.1)",
                fontFamily: "Arial, sans-serif",
                userSelect: "none",
              }}
            >
              N
            </Typography>
          </Box>
        </Fab>
      </Box>
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <div style={{ textAlign: "center", color: "#312783", marginTop: 20 }}>
          <CircularProgress />
        </div>
      );
    }

    if (hasSearched && filteredOffers.length === 0) {
      return <NoResults />;
    }

    return renderOfferList(filteredOffers.length > 0 ? filteredOffers : offers);
  };

  const renderOfferList = useCallback(
    (offersList) => {
      return (
        <Container>
          <List>
            {offersList.map((offer) => (
              <ListItem key={offer.id} style={styles.listItem}>
                <Box style={styles.contUser}>
                  <CardMedia
                    component="img"
                    image={offer.image}
                    title="Perfil"
                    style={{
                      width: 40,
                      height: 40,
                      borderRadius: "50%",
                      marginRight: 16,
                      border: "2.5px solid #312783",
                    }}
                  />
                  <Link to={`/perfil/${offer.uid}`}>
                    <Typography style={styles.name}>{offer.name}</Typography>
                  </Link>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: "flex-start",
                    alignItems: "center",
                    width: "100%",
                    mt: 3, // Espacio después del header
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      flexDirection: "column",
                      width: { xs: "100%", md: "20%" },
                      alignItems: { xs: "center", md: "flex-start" },
                      mb: { xs: 3, md: 0 },
                    }}
                  >
                    {offer.videos && offer.videos.length > 0 ? (
                      <div
                        style={{
                          position: "relative",
                          width: "100%",
                          paddingBottom:
                            aspectRatio === "16:9" ? "56.25%" : "177.78%", // Aspecto dinámico para 16:9 y 9:16
                          backgroundColor: "#000", // Fondo para cuando el video no se carga
                          overflow: "hidden",
                          borderRadius: "5px",
                        }}
                      >
                        <video
                          src={offer.videos[0]}
                          controls
                          muted
                          playsInline
                          autoPlay
                          onLoadedMetadata={handleLoadedMetadata}
                          style={{
                            position: "absolute",
                            top: "0",
                            left: "0",
                            width: "100%",
                            height: "100%",
                            objectFit: "cover", // Ajusta el video sin distorsión
                          }}
                        />
                      </div>
                    ) : offer.images && offer.images.length > 0 ? (
                      <Box style={styles.imageContainer}>
                        <CardMedia
                          component="img"
                          image={offer.images[0]}
                          alt={`Imagen principal de ${offer.name}`}
                          style={styles.mainImage}
                        />
                        {offer.images.length > 1 && (
                          <Box
                            style={styles.additionalImagesOverlay}
                            onClick={() => handleOpenImageModal(offer.images)}
                          >
                            <Typography style={styles.additionalImagesCount}>
                              +{offer.images.length - 1}
                            </Typography>
                            <Typography>Ver más</Typography>
                          </Box>
                        )}
                      </Box>
                    ) : (
                      <Box style={styles.noImage}>
                        <Typography>No hay medios disponibles</Typography>
                      </Box>
                    )}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mt: 1,
                      }}
                    >
                      <IconButton
                        onClick={() => handleMeSirveClick(offer.id)}
                        color={userLikes[offer.id] ? "primary" : "default"}
                        aria-label="me sirve"
                        disabled={!userAuthenticated}
                      >
                        <ThumbUpAltIcon />
                      </IconButton>
                      <Typography sx={{ fontSize: "11px" }}>
                        Me Gusta
                      </Typography>

                      <Typography
                        color="text.secondary"
                        sx={{ ml: 1, fontSize: "12px" }}
                      >
                        - Ha {offer.helpedCount || 0} personas les gusto esta
                        oferta
                      </Typography>
                    </Box>
                  </Box>

                  <Box
                    sx={{
                      width: { xs: "100%", md: "70%" },
                      pl: { xs: 0, md: 3 },
                    }}
                  >
                    <Typography style={styles.title}>
                      {offer.product}
                    </Typography>
                    <Typography style={styles.description}>
                      {offer.description}
                    </Typography>
                    <Typography style={styles.offer}>
                      Precio del producto: ${offer.price}
                    </Typography>
                    <Typography style={styles.offer}>
                      Descuento: {offer.offer}%
                    </Typography>
                    <CopyToClipboardComponent textToCopy={offer.cupon} />
                    <Button
                      variant="contained"
                      color="primary"
                      href={`http://${offer.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ width: "100%", mt: 1, mb: 2 }}
                    >
                      Comprar en {getDomainFromUrl(offer.url)}
                    </Button>
                    <Typography style={styles.created}>
                      Fecha de publicación: {formatDate(offer.createdAt)}
                    </Typography>
                    <Box>
                      <Button
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={() => handleFollowClick(offer.uid)}
                        disabled={loading}
                      >
                        {isCompanyFollowed(offer.uid)
                          ? "Dejar de seguir"
                          : "Seguir"}
                      </Button>
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ mt: 2 }}>
                  <Typography variant="h6">Preguntas:</Typography>
                  <List>
                    {questionsAndAnswers[offer.id]?.map((item, index) => (
                      <Collapse
                        key={item.id}
                        in={index === 0 || showMoreQuestions[offer.id]}
                      >
                        <ListItem alignItems="flex-start">
                          <Paper elevation={3} sx={{ p: 1, width: "100%" }}>
                            <Box display="flex" alignItems="center" mb={2}>
                              <Avatar
                                src={item.profileImageUrl}
                                alt={item.displayName}
                                sx={{
                                  width: 32,
                                  height: 32,
                                  border: "2px solid #312783",
                                  mr: 1,
                                }}
                              />
                              <Typography variant="subtitle1" sx={{ ml: 2 }}>
                                <strong>{item.displayName}</strong> preguntó:
                              </Typography>
                            </Box>
                            <Typography paragraph>{item.question}</Typography>
                            <Divider sx={{ my: 2 }} />
                            {item.answers.map((answer) => (
                              <Box key={answer.id} sx={{ ml: 2, mt: 1 }}>
                                <Box display="flex" alignItems="center" mb={1}>
                                  <Avatar
                                    src={answer.profileImageUrl}
                                    alt={answer.displayName}
                                    sx={{
                                      width: 32,
                                      height: 32,
                                      border: "2px solid #312783",
                                      mr: 1,
                                    }}
                                  />
                                  <Typography
                                    variant="subtitle1"
                                    sx={{ ml: 2 }}
                                  >
                                    <strong>{answer.displayName}</strong>{" "}
                                    respondió:
                                  </Typography>
                                </Box>
                                <Typography paragraph>
                                  {answer.answer}
                                </Typography>
                              </Box>
                            ))}
                            {auth.currentUser?.uid === offer.uid &&
                              !item.answers.length && (
                                <Box sx={{ display: "flex", mt: 1 }}>
                                  <TextField
                                    fullWidth
                                    variant="outlined"
                                    size="small"
                                    placeholder="Escribe una respuesta..."
                                    value={newAnswer[item.id] || ""}
                                    onChange={(e) =>
                                      setNewAnswer((prevAnswers) => ({
                                        ...prevAnswers,
                                        [item.id]: e.target.value,
                                      }))
                                    }
                                    inputRef={(ref) =>
                                      (answerInputRef.current[item.id] = ref)
                                    }
                                  />
                                  <Button
                                    variant="contained"
                                    color="primary"
                                    sx={{ ml: 1 }}
                                    onClick={() =>
                                      handleAnswerSubmit(item.id, offer.id)
                                    }
                                  >
                                    Responder
                                  </Button>
                                </Box>
                              )}
                          </Paper>
                        </ListItem>
                      </Collapse>
                    ))}
                  </List>
                  {questions[offer.id]?.length > 1 && (
                    <Typography
                      onClick={() =>
                        setShowMoreQuestions((prev) => ({
                          ...prev,
                          [offer.id]: !prev[offer.id],
                        }))
                      }
                      sx={{
                        mt: 1,
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                    >
                      {showMoreQuestions[offer.id]
                        ? "Mostrar menos preguntas"
                        : "Mostrar más preguntas"}
                    </Typography>
                  )}

                  <Box sx={{ display: "flex", mt: 1 }}>
                    <TextField
                      fullWidth
                      placeholder="Escribe una pregunta..."
                      value={newQuestion}
                      onChange={(e) => setNewQuestion(e.target.value)}
                    />
                    <Button
                      sx={{ ml: 1 }}
                      variant="contained"
                      onClick={() => handleQuestionSubmit(offer.id)}
                    >
                      Preguntar
                    </Button>
                  </Box>
                </Box>
              </ListItem>
            ))}
          </List>
        </Container>
      );
    },
    [
      aspectRatio,
      userLikes,
      handleMeSirveClick,
      handleFollowClick,
      isCompanyFollowed,
      handleOpenImageModal,
      handleQuestionSubmit,
      handleAnswerSubmit,
      newQuestion,
      newAnswer,
      showMoreQuestions,
      questionsAndAnswers,
    ]
  );

  return (
    <Container>
      <AppBar position="fixed" sx={classes.appBar}>
        <Toolbar sx={classes.toolbar}>
          <IconButton href="/home" sx={classes.iconButton}>
            <HomeIcon sx={classes.icon} />
          </IconButton>

          <IconButton href="/members" sx={classes.iconButton}>
            <SearchIcon sx={classes.icon} />
          </IconButton>

          <Box sx={{ position: "relative" }}>
            <QRButton onClick={handleOpenScanner} />
          </Box>

          <IconButton href="/notifications" sx={classes.iconButton}>
            <NotificationIcon
              hasNewNotifications={hasNewNotifications}
              sx={classes.icon}
            />
          </IconButton>

          <IconButton href="/profile" sx={classes.iconButton}>
            {profileImageUrl ? (
              <Avatar src={profileImageUrl} sx={classes.avatar} />
            ) : (
              <Avatar sx={classes.avatar} />
            )}
          </IconButton>
        </Toolbar>
      </AppBar>
      <AppBar position="fixed" sx={styles.topAppBar}>
        <Toolbar sx={styles.logoToolbar}>
          <CardMedia
            component="img"
            image={logoSvg}
            title="Notiline"
            style={styles.media}
          />
        </Toolbar>
        <CustomToolbar />
      </AppBar>
      <Container sx={{ mt: 20, mb: 10 }}>
        <WelcomeDialog />
        <QRScanner
          open={isQRScannerOpen}
          onClose={() => setIsQRScannerOpen(false)}
        />
        {renderFloatingSearch()}
        {renderContent()}
        <Dialog
          open={openImageModal}
          onClose={handleCloseImageModal}
          aria-labelledby="image-modal-title"
          aria-describedby="image-modal-description"
          fullScreen
        >
          <DialogTitle id="image-modal-title">
            Imágenes del producto
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="image-modal-description">
              {openImageModal && (
                <Box style={styles.imageModalContent}>
                  {modalImages && modalImages.length > 0 ? (
                    <CardMedia
                      component="img"
                      image={modalImages[currentImageIndex]}
                      alt={`Imagen ${currentImageIndex + 1} del alojamiento`}
                      style={styles.imageModalImage}
                    />
                  ) : (
                    <Typography>No hay imágenes disponibles</Typography>
                  )}
                  <Box style={styles.imageModalNavigation}>
                    <IconButton onClick={handlePreviousImage}>
                      <ArrowBackIosIcon />
                    </IconButton>
                    <IconButton onClick={handleNextImage}>
                      <ArrowForwardIosIcon />
                    </IconButton>
                  </Box>
                </Box>
              )}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseImageModal}>Cerrar</Button>
          </DialogActions>
        </Dialog>
      </Container>
      <QRScanner
        open={isQRScannerOpen}
        onClose={() => setIsQRScannerOpen(false)}
      />
    </Container>
  );
};

export default Home;
