import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Container, AppBar, Toolbar, Typography, TextField, Button,
  Select, MenuItem, CircularProgress, Box, Grid, Snackbar, Alert, InputAdornment
} from "@mui/material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import { auth, db, uploadImage } from "../../firebase";
import { doc, getDoc, addDoc, collection, serverTimestamp } from "firebase/firestore";
import Atras from "../atras";
import { useSubscriptionContext } from "../SubscriptionContext";



const FORM_VALIDATION = {
  title: {
    required: true, minLength: 5, maxLength: 100,
    validate: (value) => {
      if (!value) return "El título es obligatorio";
      if (value.length < 5) return "El título debe tener al menos 5 caracteres";
      if (value.length > 100) return "El título no puede exceder 100 caracteres";
      return null; // Return null instead of empty string for no error
    },
  },
  description: {
    required: true, minLength: 20, maxLength: 1000,
    validate: (value) => {
      if (!value) return "La descripción es obligatoria";
      if (value.length < 20) return "La descripción debe tener al menos 20 caracteres";
      return null;
    },
  },
  price: {
    required: true,
    validate: (value) => {
      if (!value) return "El precio es obligatorio";
      const price = parseFloat(value);
      if (isNaN(price)) return "Ingrese un precio válido";
      if (price <= 0) return "El precio debe ser mayor a 0";
      return null;
    },
  },
  place: {
    required: true,
    validate: (value) => !value ? "El lugar es obligatorio" : null,
  },
  category: {
    required: true,
    validate: (value) => !value ? "La categoría es obligatoria" : null,
  },
  city: {
    required: true,
    validate: (value) => {
      if (!value) return "La ciudad es obligatoria";
      if (value.length < 2) return "El nombre de la ciudad es demasiado corto";
      return null;
    },
  },
  images: {
    required: true,
    validate: (value) => {
      if (!Array.isArray(value) || value.length === 0) return "Debe subir al menos una imagen";
      if (value.length > 15) return "No puede subir más de 15 imágenes";
      return null;
    },
  },
};

const Publish = () => {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    price: "",
    place: "",
    category: "",
    city: "",
    timeToDo: new Date(),
    images: [],
  });
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [publishSuccess, setPublishSuccess] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "error",
  });
  const navigate = useNavigate();
  const { subscription } = useSubscriptionContext(); // Correct usage

  useEffect(() => {
    if (!subscription) {
      navigate('/subscription'); // Redirige si no hay suscripción activa
    }
  }, [subscription, navigate]);


  const validateField = (name, value) => {
    const fieldValidation = FORM_VALIDATION[name];
    return fieldValidation?.validate(value); // Use optional chaining
  };
  
  const validateForm = () => {
    const errors = {};
    for (const fieldName in FORM_VALIDATION) {
      const error = validateField(fieldName, formData[fieldName]);
      if (error) {
        errors[fieldName] = error;
      }
    }
    return errors; // Return the errors object directly
  };



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    // Validate field on change
    const error = validateField(name, value);
    setFormErrors((prev) => ({
      ...prev,
      [name]: error,
    }));
  };

  const handlePublishProyect = async () => {
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setSnackbar({ open: true, message: "Por favor, corrija los errores en el formulario.", severity: "error" });
      return;
    }

    try {
      setLoading(true);
      const { uid } = auth.currentUser || {};
      if (!uid) throw new Error("Usuario no autenticado");

      const userDocRef = doc(db, "users", uid);
      const userDocSnapshot = await getDoc(userDocRef);

      if (!userDocSnapshot.exists()) {
        throw new Error("No se encontró el documento del usuario");
      }

      const userData = userDocSnapshot.data();
      await addDoc(collection(db, "places"), {
        name: userData.displayName,
        ...formData,
        createdAt: serverTimestamp(),
        uid,
      });

      setPublishSuccess(true);
      setSnackbar({
        open: true,
        message: "Propiedad publicada exitosamente",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: `Error al publicar: ${error.message}`,
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleImageUpload = async (event) => {
    const files = event.target.files;

    if (!files) return;

    // Validar límite de imágenes
    if (formData.images.length + files.length > 10) {
      setFormErrors((prev) => ({
        ...prev,
        images: "No puede subir más de 10 imágenes",
      }));
      return;
    }

    setLoading(true);

    try {
      const imageUrls = [];
      for (const file of files) {
        const imageUrl = await uploadImage(file);
        imageUrls.push(imageUrl);
      }

      setFormData((prev) => ({
        ...prev,
        images: [...prev.images, ...imageUrls],
      }));

      // Limpiar error si hay imágenes
      setFormErrors((prev) => ({
        ...prev,
        images: "",
      }));
    } catch (error) {
      setFormErrors((prev) => ({
        ...prev,
        images: "Error al subir las imágenes",
      }));
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveImage = (index) => {
    const updatedImages = formData.images.filter((_, i) => i !== index);
    setFormData((prev) => ({
      ...prev,
      images: updatedImages,
    }));

    // Validar si no quedan imágenes
    if (updatedImages.length === 0) {
      setFormErrors((prev) => ({
        ...prev,
        images: "Debe subir al menos una imagen",
      }));
    }
  };

  const handleRedirectHome = () => {
    navigate("/home");
  };

  const handlePublishClick = async () => {
    await handlePublishProyect();
  };

  const styles = {
    uploadButton: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%",
      height: "50px",
      backgroundColor: "#f0f0f0",
      border: "2px solid #312783",
      marginTop: "20px",
      borderRadius: "8px",
      color: "black",
    },
    progress: {
      position: "absolute",
      color: "#312783",
    },
    imagePreview: {
      width: "100px",
      height: "100px",
      objectFit: "cover",
      margin: "5px",
      border: "1px solid #ccc",
      borderRadius: "5px",
    },
    removeButton: {
      display: "block",
      position: "absolute",
      top: "5px",
      right: "5px",
      width: "60px",
      height: "60px",
      backgroundColor: "red",
      color: "white",
      borderRadius: "50%",
      cursor: "pointer",
    },
  };

  return (
    <Container>
      <AppBar position="fixed" sx={{ bgcolor: "#312783" }}>
        <Toolbar
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            padding: "10px",
          }}
        >
          <Atras color="inherit" />
          <Container style={styles.user}>
            <Typography variant="h5" color="white" gutterBottom>
              Publicar Propiedad
            </Typography>
          </Container>
        </Toolbar>
      </AppBar>
      <Container sx={{ mt: 14, mb: 20 }}>
        <TextField
          autoFocus
          margin="dense"
          id="title"
          label="Título"
          type="text"
          fullWidth
          name="title"
          required
          error={!!formErrors.title}
          helperText={formErrors.title}
          value={formData.title}
          onChange={handleInputChange}
        />
        <TextField
          margin="dense"
          id="description"
          label="Describe la propiedad"
          type="text"
          fullWidth
          name="description"
          multiline
          required
          error={!!formErrors.description}
          helperText={formErrors.description}
          rows={5}
          value={formData.description}
          onChange={handleInputChange}
          className={styles.textField}
        />
        <TextField
          margin="dense"
          id="price"
          label="Precio en USD"
          type="text"
          fullWidth
          required
          name="price"
          value={formData.price}
          onChange={(e) => {
            const value = e.target.value;

            // Solo permite números y un punto decimal
            if (/^\d*\.?\d*$/.test(value)) {
              // Si hay punto decimal, limita a 2 decimales
              if (value.includes(".")) {
                const [whole, decimal] = value.split(".");
                const formattedValue = `${whole}.${decimal.slice(0, 2)}`;
                setFormData((prev) => ({
                  ...prev,
                  price: formattedValue,
                }));
              } else {
                setFormData((prev) => ({
                  ...prev,
                  price: value,
                }));
              }
            }
          }}
          onBlur={(e) => {
            // Al perder el foco, asegura formato con 2 decimales
            const value = e.target.value;
            if (value) {
              const numberValue = parseFloat(value);
              if (!isNaN(numberValue)) {
                setFormData((prev) => ({
                  ...prev,
                  price: numberValue.toFixed(2),
                }));
              }
            }
          }}
          error={!!formErrors.price}
          helperText={formErrors.price}
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
        />
        <Typography sx={{ mt: 2 }}>Tipo de publicación:</Typography>
        <Select
          label="Categoría"
          placeholder="Selecciona una categoría"
          type="text"
          name="category"
          sx={{ mt: 1.5, marginBottom: 1 }}
          value={formData.category}
          onChange={handleInputChange}
          displayEmpty
          fullWidth
          required
          error={!!formErrors.category}
          helperText={formErrors.category}
        >
          <MenuItem value="" disabled>
            Selecciona una categoría
          </MenuItem>
          <MenuItem value="alojamiento">Alojamiento</MenuItem>
          <MenuItem value="compra">Compra</MenuItem>
          <MenuItem value="alquiler">Alquiler</MenuItem>
        </Select>
        <Typography sx={{ mt: 2 }}>Seleccione un lugar:</Typography>
        <Select
          label="Lugares"
          placeholder="Selecciona un lugar"
          type="text"
          name="place"
          sx={{ mt: 1.5, marginBottom: 1 }}
          value={formData.place}
          onChange={handleInputChange}
          displayEmpty
          fullWidth
          required
          error={!!formErrors.place}
          helperText={formErrors.place}
        >
          <MenuItem value="" disabled>
            Selecciona un continente
          </MenuItem>
          <MenuItem value="Asia">Asia</MenuItem>
          <MenuItem value="América del Norte">América del Norte</MenuItem>
          <MenuItem value="América del Sur">América del Sur</MenuItem>
          <MenuItem value="Europa">Europa</MenuItem>
          <MenuItem value="Medio Oriente">Medio Oriente</MenuItem>
        </Select>
        <TextField
          margin="dense"
          id="city"
          label="Ciudad"
          type="text"
          fullWidth
          name="city"
          value={formData.city}
          onChange={handleInputChange}
          required
          error={!!formErrors.city}
          helperText={formErrors.city}
        />
        {/* Alert para mostrar estado de las imágenes */}
        {(formErrors.images || formData.images.length === 0) && (
          <Alert
            severity={formErrors.images ? "error" : "info"}
            sx={{ mt: 2, mb: 1 }}
          >
            {formErrors.images ||
              "Debe subir al menos una imagen de la propiedad"}
          </Alert>
        )}

        <label htmlFor="upload-button" style={{ width: "100%" }}>
          <Button
            variant="contained"
            fullWidth
            component="div"
            sx={{
              ...styles.uploadButton,
              border: formErrors.images
                ? "2px solid #d32f2f"
                : "2px solid #312783",
            }}
            disabled={loading || formData.images.length >= 10}
          >
            <AddPhotoAlternateIcon sx={{ color: "black" }} />
            {formData.images.length === 0
              ? "Subir Imagen del producto"
              : `Imágenes: ${formData.images.length}/15`}
            {loading && (
              <CircularProgress size={40} thickness={4} sx={styles.progress} />
            )}
          </Button>
        </label>

        <input
          type="file"
          accept="image/*"
          onClick={(event) => (event.target.value = null)}
          onChange={handleImageUpload}
          style={{ display: "none" }}
          id="upload-button"
          multiple
        />

        {/* Vista previa de las imágenes */}
        <Grid container sx={{ mt: 2 }}>
          {formData.images.map((imageUrl, index) => (
            <Grid item xs={12} sm={6} md={4} key={index} margin={1}>
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  border: "1.5px solid black",
                  borderRadius: "10px",
                }}
              >
                <img
                  src={imageUrl}
                  alt="Imagen de la propiedad"
                  style={styles.imagePreview}
                />
                <Button
                  variant="contained"
                  sx={styles.removeButton}
                  onClick={() => handleRemoveImage(index)}
                >
                  X
                </Button>
              </Box>
            </Grid>
          ))}
        </Grid>

        {publishSuccess && (
          <Box sx={{ textAlign: "center" }}>
            <Typography>El anuncio se ha publicado correctamente.</Typography>
            <Button onClick={handleRedirectHome} color="primary">
              Ir a Inicio
            </Button>
          </Box>
        )}
        {!publishSuccess && (
          <Button
            variant="contained"
            sx={{ mt: 3 }}
            fullWidth
            onClick={handlePublishClick} // Use handlePublishClick here
            color="primary"
          >
            Publicar
          </Button>
        )}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={6000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
            sx={{ width: "100%" }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Container>
    </Container>
  );
};

export default Publish;
