import React, { useState } from 'react';
import { ArrowRight, ShoppingBag, Calendar, Map, Car, Home, QrCode } from 'lucide-react';
import {
  Alert,
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Stack,
  Typography,
  LinearProgress,
  useTheme,
  CardMedia,
  CircularProgress
} from '@mui/material';
import { signInUser, resetPassword, signInWithGoogle } from '../firebase';
import { useNavigate } from 'react-router-dom';
import { isLoggedIn } from '../session';
import {
  Visibility,
  VisibilityOff
} from '@mui/icons-material';
import logoSvg from "../imgs/logo.svg";


const Login = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (prop) => (event) => {
    setFormData({ ...formData, [prop]: event.target.value });
    if (error) setError('');
  };

  const validateForm = () => {
    if (!formData.email || !formData.password) {
      setError('Por favor ingrese su usuario y contraseña.');
      return false;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setError('Por favor ingrese un email válido.');
      return false;
    }

    return true;
  };
  const [showPresentation, setShowPresentation] = useState(true);
  const [currentSlide, setCurrentSlide] = useState(0);
  
  const slides = [
    {
      title: "Bienvenido a Notiline",
      description: "Descubre una plataforma completa que combina comercio, eventos, alojamiento y más en una sola aplicación.",
      icon: null
    },
    {
      title: "Ofertas de Descuento",
      description: "Encuentra las mejores ofertas con cupones para tiendas online. Aprovecha promociones exclusivas en tus tiendas favoritas.",
      icon: <ShoppingBag size={48} color={theme.palette.primary.main} />
    },
    {
      title: "Eventos",
      description: "Descubre eventos importantes: inauguraciones, obras de teatro, feriados y mucho más. Mantente informado y no te pierdas nada.",
      icon: <Calendar size={48} color={theme.palette.primary.main} />
    },
    {
      title: "Mapa con Últimas Posiciones",
      description: "Accede al mapa con las reacciones y publicaciones recientes de los usuarios, una función exclusiva para smartphones.",
      icon: <Map size={48} color={theme.palette.primary.main} />
    },
    {
      title: "Vehículos",
      description: "Compra y vende vehículos nuevos y usados de manera fácil y segura. Encuentra la mejor oferta para ti.",
      icon: <Car size={48} color={theme.palette.primary.main} />
    },
    {
      title: "Alojamiento Global",
      description: "Reserva alojamientos, compra o vende propiedades, o alquila departamentos de forma sencilla y segura.",
      icon: <Home size={48} color={theme.palette.primary.main} />
    },
    {
      title: "Pagos Seguros en USD",
      description: "Genera códigos QR para pagos y escanea fácilmente con tu dispositivo móvil. Todas las transacciones se manejan en USD a través de PayPal para mayor seguridad.",
      icon: <QrCode size={48} color={theme.palette.primary.main} />
    }
  ];

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (!validateForm()) return;

    setIsLoading(true);
    try {
      const loginResponse = await signInUser(formData.email, formData.password);
      isLoggedIn(loginResponse.user);
      navigate('/home');
    } catch (error) {
      console.error(error.message);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGoogleSignIn = async () => {
    setIsLoading(true);
    try {
      const result = await signInWithGoogle();
      isLoggedIn(result.user);
      navigate(result.isNewUser ? '/edit' : '/home');
    } catch (error) {
      console.error(error.message);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleForgotPassword = async () => {
    if (!formData.email) {
      setError('Por favor ingrese su dirección de correo electrónico.');
      return;
    }

    setIsLoading(true);
    try {
      await resetPassword(formData.email);
      alert('Email de restablecimiento de contraseña enviado con éxito.');
    } catch (error) {
      console.error(error.message);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const PresentationView = () => (
    <Container maxWidth="md">
        <CardMedia
          component="img"
          image={logoSvg}
          title="Notiline"
          sx={{
            width: 180,
            height: 'auto',
            mx: 'auto',
            mt: 4,
            mb: 2
          }}
        />
      <Card sx={{ mt: 4, position: 'relative' }}>
        <LinearProgress 
          variant="determinate" 
          value={((currentSlide + 1) / slides.length) * 100}
          sx={{ position: 'absolute', top: 0, width: '100%' }}
        />
        
        <CardContent sx={{ pt: 4 }}>
          <Box sx={{ minHeight: 400, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', textAlign: 'center', p: 4 }}>
            {slides[currentSlide].icon && (
              <Box sx={{ mb: 4, transform: 'scale(1)', transition: 'transform 0.2s', '&:hover': { transform: 'scale(1.1)' } }}>
                {slides[currentSlide].icon}
              </Box>
            )}
            
            <Typography variant="h4" component="h2" gutterBottom>
              {slides[currentSlide].title}
            </Typography>
            
            <Typography variant="body1" color="text.secondary" sx={{ maxWidth: 600, mb: 4 }}>
              {slides[currentSlide].description}
            </Typography>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
            <Button
              onClick={prevSlide}
              disabled={currentSlide === 0}
              sx={{ visibility: currentSlide === 0 ? 'hidden' : 'visible' }}
            >
              Anterior
            </Button>

            <Box sx={{ display: 'flex', gap: 1 }}>
              {slides.map((_, index) => (
                <Box
                  key={index}
                  onClick={() => setCurrentSlide(index)}
                  sx={{
                    width: 8,
                    height: 8,
                    borderRadius: '50%',
                    bgcolor: currentSlide === index ? 'primary.main' : 'grey.300',
                    cursor: 'pointer',
                    transition: 'background-color 0.2s'
                  }}
                />
              ))}
            </Box>

            {currentSlide === slides.length - 1 ? (
              <Button
                variant="contained"
                onClick={() => setShowPresentation(false)}
                endIcon={<ArrowRight size={16} />}
              >
                Continuar
              </Button>
            ) : (
              <Button
                onClick={nextSlide}
                endIcon={<ArrowRight size={16} />}
              >
                Siguiente
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>
    </Container>
  );

  const LoginView = () => (
        <Container maxWidth="xs">
      <Card 
        elevation={3}
        sx={{
          mt: 8,
          pb: 4,
          borderRadius: 2
        }}
      >
        <CardMedia
          component="img"
          image={logoSvg}
          title="Notiline"
          sx={{
            width: 180,
            height: 'auto',
            mx: 'auto',
            mt: 4,
            mb: 2
          }}
        />
        
        <CardContent>
          <Typography 
            variant="h5" 
            component="h1" 
            textAlign="center"
            fontWeight="300"
            gutterBottom
          >
            Inicio de sesión
          </Typography>

          {error && (
            <Alert 
              severity="error" 
              sx={{ 
                mb: 2,
                borderRadius: 1
              }}
            >
              {error}
            </Alert>
          )}

          <Box 
            component="form" 
            onSubmit={handleSubmit} 
            noValidate
          >
            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="email">Email</InputLabel>
              <OutlinedInput
                id="email"
                type="email"
                label="Email"
                value={formData.email}
                onChange={handleChange('email')}
                placeholder="usuario@gmail.com"
                disabled={isLoading}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
              <InputLabel htmlFor="password">Contraseña</InputLabel>
              <OutlinedInput
                id="password"
                type={showPassword ? 'text' : 'password'}
                label="Contraseña"
                value={formData.password}
                onChange={handleChange('password')}
                placeholder="••••••••••••"
                disabled={isLoading}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      disabled={isLoading}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={isLoading}
              sx={{ 
                mt: 3,
                height: 42,
                textTransform: 'none',
                fontSize: '1rem'
              }}
            >
              {isLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Iniciar sesión'
              )}
            </Button>

            <Box sx={{ my: 2, position: 'relative' }}>
              <Divider>
                <Typography 
                  variant="body2" 
                  sx={{ 
                    color: 'text.secondary',
                    px: 1 
                  }}
                >
                  o
                </Typography>
              </Divider>
            </Box>

            <Button
              fullWidth
              variant="outlined"
              onClick={handleGoogleSignIn}
              disabled={isLoading}
              startIcon={
                <img
                  src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
                  alt="Google"
                  style={{ width: 18, height: 18 }}
                />
              }
              sx={{
                height: 42,
                textTransform: 'none',
                fontSize: '1rem',
                borderColor: 'divider',
                color: 'text.secondary',
                '&:hover': {
                  borderColor: 'divider',
                  bgcolor: 'action.hover'
                }
              }}
            >
              Continuar con Google
            </Button>

            <Stack 
              spacing={1} 
              alignItems="center" 
              sx={{ mt: 2 }}
            >
              <Link
                component="button"
                variant="body2"
                onClick={handleForgotPassword}
                disabled={isLoading}
                sx={{ 
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline'
                  }
                }}
              >
                Olvidé mi contraseña
              </Link>

              <Typography variant="body2" color="text.secondary">
                ¿Aún no tienes cuenta?{' '}
                <Link 
                  href="/register"
                  sx={{ 
                    textDecoration: 'none',
                    '&:hover': {
                      textDecoration: 'underline'
                    }
                  }}
                >
                  Registrarse
                </Link>
              </Typography>
            </Stack>
          </Box>
          </CardContent>
      </Card>
    </Container>
  );

  return showPresentation ? <PresentationView /> : <LoginView />;
};

export default Login;