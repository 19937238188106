import { useEffect } from 'react';
import { auth, db } from '../firebase';
import {
  query,
  collection,
  getDocs,
  where,
  onSnapshot,
  updateDoc,
  addDoc,
  doc,
  getDoc,
  serverTimestamp,
  orderBy,
  limit,
} from 'firebase/firestore';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { useLocation } from 'react-router-dom';

// Marcar todas las notificaciones como leídas
const markAllNotificationsAsRead = async () => {
  const user = auth.currentUser;
  if (!user) return;

  try {
    const userNotificationsQuery = query(
      collection(db, 'notifications'),
      where('userId', '==', user.uid),
      where('read', '==', false)
    );

    const querySnapshot = await getDocs(userNotificationsQuery);
    const updatePromises = querySnapshot.docs.map((doc) => updateDoc(doc.ref, { read: true }));
    await Promise.all(updatePromises);
  } catch (error) {
    console.error('Error marking notifications as read:', error);
  }
};

// Suscribirse a notificaciones en tiempo real
export const subscribeToNotifications = (callback) => {
  const user = auth.currentUser;
  if (!user) return;

  const userNotificationsQuery = query(
    collection(db, 'notifications'),
    where('userId', '==', user.uid),
    where('read', '==', false)
  );

  return onSnapshot(userNotificationsQuery, (snapshot) => {
    callback(snapshot.size > 0);
  });
};

// Componente de icono de notificaciones
const NotificationIcon = ({ hasNewNotifications }) => {
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === '/notifications') {
      markAllNotificationsAsRead();
    }
  }, [location.pathname]);

  return (
    <Badge color="primary" variant="dot" invisible={!hasNewNotifications}>
      <NotificationsIcon />
    </Badge>
  );
};

export default NotificationIcon;

// Escuchar nuevos mensajes en tiempo real
export const useListenForNewMessages = () => {
  useEffect(() => {
    const user = auth.currentUser;
    if (!user) return;

    const conversationsQuery = query(
      collection(db, 'conversations'),
      where('participants', 'array-contains', user.uid)
    );

    const unsubscribe = onSnapshot(conversationsQuery, (snapshot) => {
      snapshot.docChanges().forEach(async (change) => {
        if (change.type !== 'modified') return;

        const conversationData = change.doc.data();
        const otherUserId = conversationData.participants.find((id) => id !== user.uid);
        const newUnreadCount = conversationData.unreadCount?.[user.uid] || 0;

        if (newUnreadCount > 0) {
          const lastMessageQuery = query(
            collection(db, `conversations/${change.doc.id}/messages`),
            orderBy('timestamp', 'desc'),
            limit(1)
          );

          const lastMessageSnapshot = await getDocs(lastMessageQuery);
          if (lastMessageSnapshot.empty) return;

          const lastMessageDoc = lastMessageSnapshot.docs[0];
          const lastMessageId = lastMessageDoc.id;
          const notificationQuery = query(
            collection(db, 'notifications'),
            where('userId', '==', user.uid),
            where('postId', '==', lastMessageId),
            where('type', '==', 'message')
          );

          const querySnapshot = await getDocs(notificationQuery);
          if (!querySnapshot.empty) return;

          try {
            const senderDoc = await getDoc(doc(db, 'users', otherUserId));
            const senderName = senderDoc.data()?.displayName || 'Alguien';

            await addDoc(collection(db, 'notifications'), {
              userId: user.uid,
              companyId: otherUserId,
              postId: lastMessageId,
              message: `${senderName} te ha enviado un nuevo mensaje`,
              type: 'message',
              read: false,
              timestamp: serverTimestamp(),
            });
          } catch (error) {
            console.error('Error adding message notification:', error);
          }
        }
      });
    });

    return () => unsubscribe();
  }, []);
};

// Escuchar nuevas ofertas y eventos en tiempo real
export const useListenForNewPostsAndEvents = () => {
  useEffect(() => {
    const user = auth.currentUser;
    if (!user) return;

    const handleNewItem = async (change, type) => {
      if (change.type !== 'added') return;

      const newItem = change.doc.data();
      const itemUserId = newItem.userId || newItem.uid;
      if (!itemUserId) return;

      const notificationQuery = query(
        collection(db, 'notifications'),
        where('userId', '==', user.uid),
        where('postId', '==', change.doc.id),
        where('type', '==', type)
      );

      const querySnapshot = await getDocs(notificationQuery);
      if (!querySnapshot.empty) return;

      try {
        await addDoc(collection(db, 'notifications'), {
          userId: user.uid,
          companyId: itemUserId,
          postId: change.doc.id,
          message: `${newItem.displayName || newItem.name} ha publicado ${type === 'offer' ? 'una nueva oferta' : 'un nuevo evento'}`,
          type,
          read: false,
          timestamp: serverTimestamp(),
        });
      } catch (error) {
        console.error(`Error adding ${type} notification:`, error);
      }
    };

    const unsubscribeOffers = onSnapshot(collection(db, 'offers'), (snapshot) => {
      snapshot.docChanges().forEach((change) => handleNewItem(change, 'offer'));
    });

    const unsubscribeEvents = onSnapshot(collection(db, 'events'), (snapshot) => {
      snapshot.docChanges().forEach((change) => handleNewItem(change, 'event'));
    });

    return () => {
      unsubscribeOffers();
      unsubscribeEvents();
    };
  }, []);
};