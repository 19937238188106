import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Avatar,
  IconButton,
  Typography,
  Box,
  CircularProgress
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';

const AttendeesDialog = ({ 
  open, 
  onClose, 
  attendees, 
  userProfiles,
  loading 
}) => {
  const navigate = useNavigate();

  const handleProfileClick = (attendeeId) => {
    navigate(`/perfil/${attendeeId}`);
    onClose();
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          maxHeight: '80vh', // Limita la altura al 80% de la ventana
          borderRadius: '12px'
        }
      }}
    >
      <DialogTitle 
        sx={{ 
          display: 'flex', 
          justifyContent: 'space-between', 
          alignItems: 'center',
          bgcolor: '#f5f5f5',
          borderBottom: '1px solid #e0e0e0',
          position: 'sticky', // Mantiene el título visible
          top: 0,
          zIndex: 1,
          p: 2
        }}
      >
        <Typography variant="h6" sx={{ color: '#312783' }}>
          {loading ? 'Cargando asistentes...' : `${attendees?.length || 0} personas asistirán`}
        </Typography>
        <IconButton onClick={onClose} edge="end" sx={{ color: '#666' }}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      
      <DialogContent 
        sx={{ 
          p: 2,
          overflow: 'auto', // Habilita el scroll
          '&::-webkit-scrollbar': {
            width: '8px',
          },
          '&::-webkit-scrollbar-track': {
            backgroundColor: '#f5f5f5',
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#bbb',
            borderRadius: '4px',
            '&:hover': {
              backgroundColor: '#999'
            }
          }
        }}
      >
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress sx={{ color: '#312783' }} />
          </Box>
        ) : (
          <List sx={{ width: '100%', pt: 0 }}>
            {attendees?.map((attendeeId) => {
              const profile = userProfiles[attendeeId] || {};
              return (
                <ListItem 
                  key={attendeeId}
                  onClick={() => handleProfileClick(attendeeId)}
                  sx={{
                    cursor: 'pointer',
                    '&:hover': {
                      bgcolor: '#f5f5f5',
                    },
                    borderRadius: '8px',
                    mb: 1,
                    transition: 'all 0.2s ease'
                  }}
                >
                  <ListItemAvatar>
                    <Avatar 
                      src={profile.profileImageUrl}
                      sx={{ 
                        width: 40,
                        height: 40,
                        border: '2px solid #312783'
                      }}
                    />
                  </ListItemAvatar>
                  <ListItemText 
                    primary={
                      <Typography sx={{ 
                        color: '#312783',
                        fontWeight: 500
                      }}>
                        {profile.name || 'Usuario'}
                      </Typography>
                    }
                    secondary={profile.email}
                  />
                </ListItem>
              );
            })}
          </List>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default AttendeesDialog;