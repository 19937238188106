/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import { useNavigate } from "react-router-dom";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import {
  collection,
  onSnapshot,
  doc,
  updateDoc,
  getDocs,
  getDoc,
  serverTimestamp,
  query,
  orderBy,
  limit,
  where,
} from "firebase/firestore";
import { auth, db } from "../firebase";
import { getSession, isLoggedIn } from "../session";
import {
  CircularProgress,
  Box,
  Typography,
  Button,
  Avatar,
  Fab,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  Person as PersonIcon,
  ArrowBack as ArrowBackIcon,
  MyLocation as MyLocationIcon,
  Refresh as RefreshIcon,
  AccountBox as AccountBoxIcon,
} from "@mui/icons-material";

delete L.Icon.Default.prototype._getIconUrl;

const CenterMapOnLocation = ({ position }) => {
  const map = useMap();
  useEffect(() => {
    if (position) {
      map.setView(position, map.getZoom());
    }
  }, [position, map]);
  return null;
};

const UserMap = () => {
  const navigate = useNavigate();
  const mapRef = useRef(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [locationError, setLocationError] = useState(null);
  const [watchId, setWatchId] = useState(null);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [isTracking, setIsTracking] = useState(true);
  const [mapZoom, setMapZoom] = useState(13);
  const updateTimeoutRef = useRef(null);

  const UPDATE_INTERVAL = 1000;

  const getMostPopularEmoji = (reactions) => {
    if (!reactions || Object.keys(reactions).length === 0)
      return { emoji: "📍", count: 0, totalReactions: 0 };

    const emojiCounts = {};
    Object.values(reactions).forEach((emoji) => {
      emojiCounts[emoji] = (emojiCounts[emoji] || 0) + 1;
    });

    const [mostPopularEmoji, count] = Object.entries(emojiCounts).reduce(
      (a, b) => (a[1] > b[1] ? a : b)
    );
    const totalReactions = Object.values(emojiCounts).reduce(
      (sum, count) => sum + count,
      0
    );

    return { emoji: mostPopularEmoji, count, totalReactions };
  };

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const isAuthenticated = await isLoggedIn();
        if (!isAuthenticated) {
          navigate("/login");
          return;
        }
        const session = await getSession();
        setCurrentUser(session);

        const userDoc = await getDoc(doc(db, "users", session.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData.latitude && userData.longitude) {
            setCurrentPosition([userData.latitude, userData.longitude]);
            setMapZoom(15);
          }
        }

        setLoading(false);
      } catch (error) {
        console.error("Error de autenticación:", error);
        setError("Error de autenticación");
        setLoading(false);
      }
    };
    checkAuth();
  }, [navigate]);

  useEffect(() => {
    if (!currentUser) return;

    const statesRef = collection(db, "states");

    const unsubscribe = onSnapshot(
      query(statesRef, orderBy("createdAt", "desc")),
      async (statesSnapshot) => {
        try {
          const userIds = new Set();
          statesSnapshot.docs.forEach((doc) => {
            userIds.add(doc.data().userId);
          });

          const usersData = await Promise.all(
            Array.from(userIds).map(async (userId) => {
              const userDoc = await getDoc(doc(db, "users", userId));
              if (!userDoc.exists()) return null;

              const userData = userDoc.data();

              const userStatesQuery = query(
                collection(db, "states"),
                where("userId", "==", userId),
                orderBy("createdAt", "desc"),
                limit(1)
              );

              const userStatesSnapshot = await getDocs(userStatesQuery);
              let mostPopularEmoji = {
                emoji: "📍",
                count: 0,
                totalReactions: 0,
              };

              if (!userStatesSnapshot.empty) {
                const latestState = userStatesSnapshot.docs[0].data();
                if (
                  latestState.reactions &&
                  Object.keys(latestState.reactions).length > 0
                ) {
                  mostPopularEmoji = getMostPopularEmoji(latestState.reactions);
                }
              }

              return {
                id: userId,
                latitude: parseFloat(userData.latitude) || 0,
                longitude: parseFloat(userData.longitude) || 0,
                displayName: userData.displayName || "Usuario sin nombre",
                profileImageUrl: userData.profileImageUrl || "",
                lastUpdated: userData.lastUpdated?.toDate?.() || null,
                mostPopularEmoji,
              };
            })
          );

          const filteredUsers = usersData.filter(
            (user) => user && user.latitude !== 0 && user.longitude !== 0
          );

          setUsers(filteredUsers);
          setLoading(false);
        } catch (error) {
          console.error("Error procesando usuarios:", error);
          setError("Error al procesar usuarios");
          setLoading(false);
        }
      }
    );

    return () => unsubscribe();
  }, [currentUser]);

  return (
    <Box sx={{ height: "100vh", width: "100%", position: "relative" }}>
      <Box
        sx={{
          position: "absolute",
          bottom: 34,
          left: 16,
          zIndex: 1000,
          display: "flex",
          gap: 2,
        }}
      >
        <Fab
          color="primary"
          aria-label="back"
          onClick={() => navigate("/home")}
          size="medium"
        >
          <ArrowBackIcon />
        </Fab>
        <Fab
          color="primary"
          aria-label="profile"
          onClick={() => navigate(`/profile/${currentUser?.uid}`)}
          size="medium"
        >
          <AccountBoxIcon />
        </Fab>
      </Box>
      <MapContainer
        ref={mapRef}
        center={currentPosition || [0, 0]}
        zoom={mapZoom}
        style={{ height: "100%", width: "100%" }}
        key={
          currentPosition
            ? `${currentPosition[0]}-${currentPosition[1]}`
            : "default"
        }
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />

        {currentPosition && (
          <Marker
            position={currentPosition}
            icon={
              new L.DivIcon({
                html: `<div style="font-size: 24px;">📍</div>`,
                className: "custom-div-icon",
                iconSize: [30, 30],
                iconAnchor: [15, 30],
                popupAnchor: [0, -30],
              })
            }
          >
            <Popup closeButton={false}>
              <Box sx={{ textAlign: "center", p: 1, width: 200 }}>
                <Avatar
                  src={currentUser?.photoURL}
                  sx={{
                    width: 60,
                    height: 60,
                    margin: "0 auto 8px",
                    bgcolor: "primary.main",
                  }}
                >
                  <PersonIcon />
                </Avatar>
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  {currentUser?.displayName || "Usuario"}
                </Typography>
                <Button
                  variant="contained"
                  size="small"
                  fullWidth
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/profile/${currentUser?.uid}`);
                  }}
                >
                  Ver Perfil
                </Button>
              </Box>
            </Popup>
          </Marker>
        )}

        {users.map((user) => (
          <Marker
            key={user.id}
            position={[user.latitude, user.longitude]}
            icon={
              new L.DivIcon({
                html: `<div style="font-size: 24px;">${user.mostPopularEmoji.emoji}</div>`,
                className: "custom-div-icon",
                iconSize: [30, 30],
                iconAnchor: [15, 30],
                popupAnchor: [0, -30],
              })
            }
          >
            <Popup closeButton={false}>
              <Box sx={{ textAlign: "center", p: 1, width: 200 }}>
                <Avatar
                  src={user.profileImageUrl}
                  sx={{
                    width: 60,
                    height: 60,
                    margin: "0 auto 8px",
                    bgcolor: "primary.main",
                  }}
                >
                  <PersonIcon />
                </Avatar>
                <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
                  {user.displayName}
                </Typography>
                <Typography variant="body2">
                  Reacción más popular: {user.mostPopularEmoji.emoji} (
                  {user.mostPopularEmoji.count} reacciones)
                </Typography>
                <Typography variant="body2">
                  Total de reacciones: {user.mostPopularEmoji.totalReactions}
                </Typography>
                <Button
                  variant="contained"
                  size="small"
                  fullWidth
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/profile/${user.id}`);
                  }}
                >
                  Ver Perfil
                </Button>
              </Box>
            </Popup>
          </Marker>
        ))}
      </MapContainer>

      <Snackbar
        open={!!locationError}
        autoHideDuration={6000}
        onClose={() => setLocationError(null)}
      >
        <Alert severity="error" sx={{ width: "100%" }}>
          {locationError}
        </Alert>
      </Snackbar>

      <style>{`
        .custom-div-icon {
          background: none;
          border: none;
          text-align: center;
        }
      `}</style>
    </Box>
  );
};

export default UserMap;
