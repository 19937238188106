import { auth, db } from "../firebase";
import {
  addDoc,
  collection,
  onSnapshot,
  getDoc,
  doc,
  query,
  orderBy,
  serverTimestamp,
  deleteField,
  setDoc,
  where,
  getDocs,
  updateDoc,
} from "firebase/firestore";



export const formatDate = (date) => {
  if (date instanceof Date) {
    return date.toLocaleDateString();
  } else if (date && date.toDate instanceof Function) {
    return date.toDate().toLocaleDateString();
  }
  return "";
};


export const useListenForNewPostsAndEvents = () => {
  
  const listenForNewPostsAndEvents = async () => {
    const user = auth.currentUser;
    if (!user) {
      console.error("No user is currently authenticated");
      return;
    }

    try {
      // Consulta las empresas seguidas por el usuario
      const followedCompaniesQuery = query(
        collection(db, "followedCompanies"),
        where("userId", "==", user.uid)
      );

      const followedCompaniesSnapshot = await getDocs(followedCompaniesQuery);
      const followedCompanies = followedCompaniesSnapshot.docs.map(
        (doc) => doc.id
      );

      if (followedCompanies.length === 0) {
        console.log("User is not following any companies");
        return;
      }

      console.log("Followed companies: ", followedCompanies);

      // Escuchar nuevos posts en "offers"
      const offersQuery = collection(db, "offers");
      const unsubscribeOffers = onSnapshot(offersQuery, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            const newPost = change.doc.data();
            console.log("New offer: ", newPost);
            const postUserId = newPost.userId || newPost.uid || newPost.uide; // Asegurando el campo correcto
            if (!postUserId) {
              console.error("Offer does not have a valid userId", newPost);
              return;
            }

            if (followedCompanies.includes(postUserId)) {
              // Consultar si ya existe una notificación para este post
              const notificationQuery = query(
                collection(db, "notifications"),
                where("userId", "==", user.uid),
                where("postId", "==", change.doc.id),
                where("type", "==", "offer")
              );

              getDocs(notificationQuery)
                .then((querySnapshot) => {
                  if (querySnapshot.empty) {
                    // Añadir nueva notificación de oferta si no existe
                    addDoc(collection(db, "notifications"), {
                      userId: user.uid,
                      companyId: postUserId,
                      postId: change.doc.id,
                      message: `${
                        newPost.displayName || newPost.name
                      } ha publicado una nueva oferta`,
                      type: "offer",
                      read: false,
                      timestamp: serverTimestamp(),
                    })
                      .then(() => {
                        console.log("Offer notification added successfully");
                      })
                      .catch((error) => {
                        console.error(
                          "Error adding offer notification: ",
                          error
                        );
                      });
                  } else {
                    console.log(
                      "Offer notification already exists for this post"
                    );
                  }
                })
                .catch((error) => {
                  console.error(
                    "Error querying offer notifications: ",
                    error
                  );
                });
            }
          }
        });
      });

      // Escuchar nuevos eventos en "events"
      const eventsQuery = collection(db, "events");
      const unsubscribeEvents = onSnapshot(eventsQuery, (snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            const newEvent = change.doc.data();
            console.log("New event: ", newEvent);
            const eventUserId =
              newEvent.userId || newEvent.uid || newEvent.uide; // Asegurando el campo correcto
            if (!eventUserId) {
              console.error("Event does not have a valid userId", newEvent);
              return;
            }

            if (followedCompanies.includes(eventUserId)) {
              // Consultar si ya existe una notificación para este evento
              const notificationQuery = query(
                collection(db, "notifications"),
                where("userId", "==", user.uid),
                where("postId", "==", change.doc.id),
                where("type", "==", "event")
              );

              getDocs(notificationQuery)
                .then((querySnapshot) => {
                  if (querySnapshot.empty) {
                    // Añadir nueva notificación de evento si no existe
                    addDoc(collection(db, "notifications"), {
                      userId: user.uid,
                      companyId: eventUserId,
                      postId: change.doc.id,
                      message: `${
                        newEvent.displayName || newEvent.name
                      } ha publicado un nuevo evento`,
                      type: "event",
                      read: false,
                      timestamp: serverTimestamp(),
                    })
                      .then(() => {
                        console.log("Event notification added successfully");
                      })
                      .catch((error) => {
                        console.error(
                          "Error adding event notification: ",
                          error
                        );
                      });
                  } else {
                    console.log(
                      "Event notification already exists for this post"
                    );
                  }
                })
                .catch((error) => {
                  console.error(
                    "Error querying event notifications: ",
                    error
                  );
                });
            }
          }
        });
      });

      return () => {
        unsubscribeOffers();
        unsubscribeEvents();
      };
    } catch (error) {
      console.error("Error listening for new posts and events: ", error);
    }
  };

  listenForNewPostsAndEvents();
};

export const handleAttend = async (eventId) => {
  try {
    const user = auth.currentUser;
    if (user) {
      const eventDocRef = doc(db, "events", eventId);
      const eventDocSnap = await getDoc(eventDocRef);

      if (eventDocSnap.exists()) {
        const eventData = eventDocSnap.data();
        const attendees = eventData.attendees || [];

        const updatedAttendees = attendees.includes(user.uid)
          ? attendees.filter((attendeeId) => attendeeId !== user.uid)
          : [...attendees, user.uid];

        await updateDoc(eventDocRef, { attendees: updatedAttendees });
      }
    }
  } catch (error) {
    console.error("Error al manejar la asistencia al evento:", error);
  }
};

export const fetchAttendees = async (events, setAttendees, setAttendeeProfiles) => {
  const attendeesData = {};
  const profilesData = {};

  const fetchPromises = events.map(async (event) => {
    const eventDocRef = doc(db, "events", event.id);
    const eventDocSnap = await getDoc(eventDocRef);

    if (eventDocSnap.exists()) {
      const eventData = eventDocSnap.data();
      attendeesData[event.id] = eventData.attendees || [];

      const attendeePromises = attendeesData[event.id].map(async (attendeeId) => {
        if (!profilesData[attendeeId]) {
          const userDocRef = doc(db, "users", attendeeId);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            profilesData[attendeeId] = {
              profileImageUrl: userData.profileImageUrl || "",
              name: userData.name || userData.displayName || "Usuario",
            };
          }
        }
      });

      await Promise.all(attendeePromises);
    }
  });

  await Promise.all(fetchPromises);

  setAttendees(attendeesData);
  setAttendeeProfiles(profilesData);
};

export const handleFollow = async (companyId, following, setFollowing, setLoading) => {
  try {
    const { uid } = auth.currentUser || {};
    if (uid) {
      setLoading(true);
      const userFollowingRef = doc(db, "userFollowing", uid);
      const companyFollowersRef = doc(db, "companyFollowers", companyId);

      const isFollowing = following.includes(companyId);

      if (isFollowing) {
        await updateDoc(userFollowingRef, {
          [companyId]: deleteField(),
        });
        await updateDoc(companyFollowersRef, {
          [uid]: deleteField(),
        });
        setFollowing(following.filter((id) => id !== companyId));
      } else {
        await setDoc(userFollowingRef, { [companyId]: true }, { merge: true });
        await setDoc(companyFollowersRef, { [uid]: true }, { merge: true });
        setFollowing([...following, companyId]);
      }
      setLoading(false);
    }
  } catch (error) {
    console.error("Error al manejar el seguimiento de la empresa:", error);
    setLoading(false);
  }
};

export const fetchFollowing = async (setFollowing, setLoading) => {
  try {
    const { uid } = auth.currentUser || {};
    if (uid) {
      const userFollowingRef = doc(db, "userFollowing", uid);
      const userFollowingDoc = await getDoc(userFollowingRef);
      
      if (userFollowingDoc.exists()) {
        const followingData = userFollowingDoc.data();
        const followingIds = Object.keys(followingData);
        setFollowing(followingIds);
      } else {
        setFollowing([]);
      }
    }
    setLoading(false);
  } catch (error) {
    console.error("Error al obtener las empresas seguidas:", error);
    setLoading(false);
  }
};

export const fetchEvents = async (setLoading, setEvents) => {
  try {
    setLoading(true);
    const eventsCollectionRef = collection(db, "events");
    const unsubscribe = onSnapshot(
      query(eventsCollectionRef, orderBy("createdAt", "desc")),
      (snapshot) => {
        const eventsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setLoading(false);
        setEvents(eventsData);
        console.log("Eventos obtenidos:", eventsData);
      }
    );
    return unsubscribe;
  } catch (error) {
    console.error("Error al obtener eventos:", error);
    setLoading(false);
    setEvents([]); // Asegurarse de establecer un array vacío en caso de error
  }
};